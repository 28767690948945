<template>
<div class="required_vue-root">
    <p v-if="existe_contenu">
        <a href="#" @click.prevent="viderSelection">Tout décocher</a>
        &nbsp;&nbsp;&nbsp;
        <a href="#" @click.prevent="supprimerDeSelection">Supprimer de la sélection</a>
        &nbsp;&nbsp;&nbsp;
        <a href="#" @click.prevent="creerLiens">Créer des liens dans le dossier courant</a>
    </p>
    <p v-else>
        Pour sélectionner des documents ou dossiers, utilisez l'icône
        <img :src="images.imageSelectionner" /> sur l'élément concerné.
        <br/>
        Cette action n'a aucun effet sur les documents ou dossiers.
    </p>

    <ListeNoeuds
        v-if="dossiers.length > 0"
        :designation="'Dossiers'"
        :noeuds="dossiers"
        @maj_selection="miseAJourSelection(selection.dossiers, $event)"
        :key="refreshKeyDossiers"
    />

    <ListeNoeuds
        v-if="documents.length > 0"
        :designation="'Documents'"
        :noeuds="documents"
        @maj_selection="miseAJourSelection(selection.documents, $event)"
        :key="refreshKeyDocuments"
    />
    </div>
</template>

<script>
    import ListeNoeuds from './liste_noeuds'

    import apiDossiers from '../../../api/dossiers'

    import erreursHelpers from '../../helpers/erreurs'
    import noeudHelpers from '../../helpers/noeud'
    import bus from '../../helpers/bus'

    import imageSelectionner from '../../../../assets/images/icones/link_go.png'

    export default {
        data: () => {
            return {
                // c'est la sélection (cases à cocher) dans la sélection
                selection: {
                    dossiers: [],
                    documents: []
                },
                refreshKey: 0,
                images: {
                    imageSelectionner,
                }
            }
        },

        computed: {
            dossiers: function() {
                return this.$store.state.selection.dossiers
            },
            documents: function() {
                return this.$store.state.selection.documents
            },
            existe_contenu: function() {
                return this.dossiers.length > 0 || this.documents.length > 0
            },
            refreshKeyDossiers: function() {
                return `dossiers_${this.refreshKey}`
            },
            refreshKeyDocuments: function() {
                return `documents_${this.refreshKey}`
            },
        },

        methods: {
            miseAJourSelection: function(selection, {noeud, ajout}) {
                if(ajout) {
                    selection.push(noeud)
                } else {
                    let index = selection.indexOf(noeud)
                    if(index > -1) {
                        selection.splice(index, 1)
                    }
                }
            },
            viderSelection: function() {
                this.selection.dossiers.splice(0, this.selection.dossiers.length)
                this.selection.documents.splice(0, this.selection.documents.length)
                this.refreshKey += 1
            },
            supprimerDeSelection: function() {
                if(!this._verifieSelection()) {
                    return
                }

                for(let dossier of this.selection.dossiers) {
                    this.$store.commit('removeSelectionDossier', dossier)
                }

                for(let document of this.selection.documents) {
                    this.$store.commit('removeSelectionDocument', document)
                }
            },
            creerLiens: async function() {
                if(!this._verifieSelection()) {
                    return
                }

                for(let dossier of this.selection.dossiers) {
                    let lien
                    try {
                        lien = await apiDossiers.creerLienDossier(this.$store.state.dossier_courant.id, dossier.id)
                    }
                    catch(error) {
                        erreursHelpers.alertMessageErreur(error)
                        continue
                    }
                    bus.nouveauDossier(lien)
                }

                for(let document of this.selection.documents) {
                    let lien
                    try {
                        lien = await apiDossiers.creerLienDocument(this.$store.state.dossier_courant.id, document.id)
                    }
                    catch(error) {
                        erreursHelpers.alertMessageErreur(error)
                        continue
                    }
                    bus.nouveauDocument(lien)
                }
            },
            _verifieSelection: function() {
                if(this.selection.dossiers.length === 0 && this.selection.documents.length === 0) {
                    alert("Il faut sélectionner des éléments avant de créer des liens.")
                    return false
                }
                return true
            }
        },

        components: {
            ListeNoeuds,
        }
    }
</script>