<template>
    <div class="zone_filtre_pagination">
        <p>
            Dossiers commençant par &nbsp;
            <input class="type_texte" maxlength="32" size="12" type="text"
                    v-model="prefixe"
                    v-on:keyup="saisiePrefixe">
        </p>

        <ul>
            <li v-if="afficherRetourRapide">
                <a href="#" v-on:click.prevent="retourRapide">
                    &lt;&lt;&lt;
                </a>
            </li>
            <li v-for="index in (dernierePage - premierePage + 1)" v-bind:key="noPageFromIndex(index)">
                <a href="#"
                   v-if="noPageFromIndex(index) !== page"
                   v-on:click.prevent="allerAPage(noPageFromIndex(index))">
                    {{ noPageFromIndex(index) }}
                </a>
                <span v-else>{{ noPageFromIndex(index) }}</span>
            </li>

            <li v-if="afficherAvanceRapide">
                <a href="#" v-on:click.prevent="avanceRapide">
                    &gt;&gt;&gt;
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import _ from 'lodash'

    const deboundePrefixeMs = 500
    const nbPagesProposees = 9
    const moitiePages = Math.floor(nbPagesProposees/2)
    
    export default {
        data: () => {
            return {
                prefixe: null,
            }
        },

        props: ['prefixe_initial', 'page', 'total_pages'],

        created: function() {
            this.prefixe = this.prefixe_initial
        },

        computed: {
            premierePage: function() {
                let premiere = this.page - moitiePages
                return premiere > 0 ? premiere : 1
            },

            dernierePage: function() {
                let derniere = this.premierePage + nbPagesProposees - 1
                return derniere < this.total_pages ? derniere : this.total_pages
            },

            afficherRetourRapide: function() {
                return this.premierePage > 1
            },

            afficherAvanceRapide: function() {
                return this.dernierePage < this.total_pages
            },

            cibleRetourRapide: function() {
                let cible = this.page - nbPagesProposees
                return cible > 1 ? cible : 1
            },

            cibleAvanceRapide: function() {
                let cible = this.page + nbPagesProposees
                return cible <= this.total_pages ? cible : 1
            }
        },

        methods: {
            saisiePrefixe: _.debounce(function() {
                this.$emit('change_prefixe', this.prefixe)
            } , deboundePrefixeMs),

            allerAPage: function(nopage) {
                this.$emit('change_page', nopage)
            },

            avanceRapide: function() {
                this.$emit('change_page', this.cibleAvanceRapide)
            },

            retourRapide: function() {
                this.$emit('change_page', this.cibleRetourRapide)
            },

            noPageFromIndex: function(index) {
                // index comment à 1 (cf doc Vue)
                return this.premierePage + index - 1
            }
        },

        watch: {
            prefixe_initial: function() {
                // Peut changer lorsque le parent a besoin de temps pour déterminer
                // un préfixe : dans le cas de l'affichage d'une arbo complète
                // de la racine au noeud cible.
                this.prefixe = this.prefixe_initial
            }
        }
    }
</script>
