import chaineHelpers from './chaines'
import _ from 'lodash'

function estRacine(noeud) {
    return noeud?.parent_id === null
}

function estUnDossier(noeud) {
    return noeud?.type_noeud === 0
}

function estUnDocument(noeud) {
    return noeud?.type_noeud === 1
}

function estSupprime(noeud) {
    return noeud?.supprime_le !== null
}

function estUnLien(noeud) {
    return noeud?.noeud_reel_id > 0
}

function noeudReelId(noeud) {
    return estUnLien(noeud) ? noeud.noeud_reel_id : noeud.id
}

function identifiantFormate(noeud, limite = 30) {
    if(limite < 10) {
        console.log("Valeur limite pour la taille de l'identifiant suspecte : ", limite)
        limite = 10
    }
    return chaineHelpers.limiteTailleChaine(noeud?.identifiant, limite)
}

function descriptionFormatee(noeud) {
    return chaineHelpers.LFtoBR(chaineHelpers.limiteTailleChaine(noeud?.description, 50))
}

function versionDocumentLePlusRecent(noeud) {
    if(noeud?.type_noeud !== 1) {
        console.log('Ce doit être un noeud document')
        return
    }

    if(!noeud.versions_documents || noeud.versions_documents.length === 0) {
        console.log('Il n\'y a aucune version de document associée au noeud')
        return
    }

    let max_no_version = -1
    let max_version_document = null
    noeud.versions_documents.forEach(version => {
        if(version.no_version > max_no_version) {
            max_no_version = version.no_version
            max_version_document = version
        }
    })

    return max_version_document
}

function motsClesArrayToString(tableauMotsCles) {
    let tableau_mots_cles =  _.filter(tableauMotsCles, (mot) => {
        return mot?.length > 0
    })
    tableau_mots_cles = _.map(tableau_mots_cles, (mot) => {
        return mot.trim()
    })
    return tableau_mots_cles.join(', ')
}

function motsClesStringToArray(chaineMotsCles) {
    let tableau_mots_cles = _.map(chaineMotsCles.split(','), (mot) => {
        return mot.trim()
    })
    tableau_mots_cles = _.filter(tableau_mots_cles, (mot) => {
        return mot?.length > 0
    })

    return tableau_mots_cles
}
function identifiantValide(identifiantIncertain) {
    return identifiantIncertain
            // pas d'espaces au debut
            .replaceAll(/^\W+/g,"")
            // pas d'espace à la fin
            .replaceAll(/\W+$/g,"")
            // pas d'espaces consecutifs
            .replaceAll(/\W+/g, " ")
            // ne conserver que les caractères ascii alpha (ascii), digit, ""."" et espaces/
            .replaceAll(/[^\w\d\.\W]+/g, "_")
            // pas plus de deux "_" consecutifs
            .replaceAll(/_+/g,"_")
}


export default {
    estRacine,
    estUnDossier,
    estUnDocument,
    estSupprime,
    estUnLien,
    noeudReelId,
    identifiantFormate,
    descriptionFormatee,
    versionDocumentLePlusRecent,
    motsClesArrayToString,
    motsClesStringToArray,
    identifiantValide
}