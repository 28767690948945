<template>
    <div class="required_vue_root">
        <DetailsContenuDossier
            v-if="estUnDossier"
            v-bind:dossier_courant="noeudCourant" />

        <DetailsContenuDocument
            v-if="estUnDocument"
            v-bind:document_courant="noeudCourant" />
    </div>
</template>

<script>
    import apiDossiers from '../../../api/dossiers'

    import noeudHelpers from '../../helpers/noeud'

    import DetailsContenuDossier from './details_contenu_dossier'
    import DetailsContenuDocument from './details_contenu_document'

    export default {
        data: () => {
            return {
                unwatchNoeuCourant: null,
            }
        },

        created: function() {
            this.changementNoeudCourant()
            this.unwatchNoeuCourant = this.$store.watch(
                (state, getters) => getters.noeudCourant,
                (newValue, oldValue) => {
                    this.changementNoeudCourant()
                }
            )
        },

        beforeDestroy: function() {
            if(this.unwatchNoeuCourant) {
                this.unwatchNoeuCourant()
            }
        },

        computed: {
            noeudCourant: function() {
                return this.$store.getters.noeudCourant
            },
            estUnDossier: function() {
                return noeudHelpers.estUnDossier(this.noeudCourant)
            },
            estUnDocument: function() {
                return noeudHelpers.estUnDocument(this.noeudCourant)
            },
        },

        methods: {
            changementNoeudCourant: function() {
                let noeud_courant = this.$store.getters.noeudCourant
                if(!noeud_courant) {
                    return
                }
            }
        },

        components: {
            DetailsContenuDossier,
            DetailsContenuDocument
        }

    }
</script>
