<template>
    <table class="liste_dans_onglets liste_dans_onglets_navigation">
        <tbody>
            <tr>
                <th>Familles</th>
                <th>Champs</th>
                <th>Valeurs</th>
            </tr>
            <tr v-for="m of flat_metadonnees" :key="m.param_id">
                <td>{{ m.famille }}</td>
                <td>{{ m.metadonnee }}</td>
                <td>
                    <span :class="(m.existe_valeur) ? '' : 'grey'">
                        {{ m.valeur }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import metadonneesHelpers from '../../helpers/metadonnees'

    export default {
        data: () => {
            return {
            }
        },

        props: ['noeud', 'familles_meta'],

        computed: {
            flat_metadonnees: function() {
                return metadonneesHelpers.flatMetadonnees(this.noeud, this.familles_meta)
            },
        },
    }
</script>
