import http from './http'

function getCategoriesIdentifiants() {
    let url = `categoriesidentifiants`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

export default { getCategoriesIdentifiants }