<template>
    <div :class="classes['exterieur']">
        <ul class="onglets_larges">
            <li v-for="(onglet, index) in listeOnglets" v-bind:key="index"
                v-bind:class="classActifInactif(index)">
                <a href="#"
                   v-bind:class="classActifInactif(index)"
                   v-on:click.prevent="selectionOnglet(index)">
                    {{ libelleOnglet(index) }}
                </a>
            </li>
        </ul>
        
        <div :class="classes['interieur']" :style="style_pour_hauteur_min">
            <component v-bind:is="composantCourant" v-bind="propsCourant" :key="noOngletCourant" />
        </div>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {
                noOngletCourant: 0
            }
        },

        props: ['listeOnglets', 'classes', 'min_height', 'max_height'],

        computed: {
            composantCourant: function() {
                return this.listeOnglets[this.noOngletCourant].composant;
            },
            propsCourant: function() {
                return this.listeOnglets[this.noOngletCourant].props ?? null;
            },
            style_pour_hauteur_min: function() {
                return `min-height: ${this.min_height}; max-height: ${this.max_height};`
            }
        },

        methods: {
            classActifInactif: function(noOnglet) {
                return noOnglet === this.noOngletCourant ? this.classes['actif'] : this.classes['inactif']
            },

            libelleOnglet: function(noOnglet) {
                return this.listeOnglets[noOnglet].libelle
            },

            selectionOnglet(noOnglet) {
                this.noOngletCourant = noOnglet
            }
        },

        watch: {
            listeOnglets: function() {
                if(this.noOngletCourant >= this.listeOnglets.length) {
                    this.noOngletCourant = 0
                }
            }
        }
    }
</script>
