function estErreur4xx(erreur) {
    return erreur.response?.status >= 400 && erreur.response?.status < 500
}

function estErreur5xx(erreur) {
    return erreur.response?.status >= 500 && erreur.response?.status < 600
}

function estTimeOut(erreur) {
    return erreur.request && !erreur.response
}

function traiteErreurOuRetourne4xxInfo(erreur, traite404 = true) {
    if(estErreur4xx(erreur) && (!traite404 || erreur.response?.status !== 404)) {
        return erreur.response.data
    }

    alertMessageErreur(erreur)
    return null
}

function alertMessageErreur(erreur) {
    let message = 'Un incident est survenu lors de la requête\n'
    message += `(${new Date()})\n\n`

    if(estErreur5xx(erreur)) {
        message += 'Le serveur a rencontré un incident lors du traitement de la requête\n\n'
    } else if(estTimeOut(erreur)) {
        message += "Aucune réponse n'a été obtenue (problème réseau ou serveur indisponible)\n\n"
    }
    message += erreur.message

    let contenu_reponse = erreur?.response?.data?.error
    if(contenu_reponse) {
        message += "\n"
        message += contenu_reponse
    }

    console.log(message)
    alert(message)
}

export default {
    traiteErreurOuRetourne4xxInfo,
    alertMessageErreur,
}