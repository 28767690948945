import http from './http'

function getCategoriesOrigines() {
    let url = `categoriesorigines`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

export default { getCategoriesOrigines }