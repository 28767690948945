<template>
    <div class="required_vue_root">
        <br/>
        
        <div v-if="!etatEditionDossier" class="artificial_container" >
            <div id="zone_identifiant">
                <p>
                    Libellé : <strong>{{ identifiantFormate }}</strong>
                </p>

                <p v-if="etatVisualisation">
                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherAjouterDossier"
                        v-on:click.prevent="ajouterDossier" >
                        <img v-bind:src="images.imageAjoutDossier" />
                        <span class="bulle_a_droite">Ajouter un sous-dossier</span>
                    </a>
                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherAjouterDocument"
                        v-on:click.prevent="ajouterDocument" >
                        <img v-bind:src="images.imageAjoutDocument" />
                        <span class="bulle_a_droite">Ajouter un document</span>
                    </a>
                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherEditerLibelle"
                        v-on:click.prevent="editerlibelle" >
                        <img v-bind:src="images.imageEditer" />
                        <span class="bulle_a_droite">Modifier le libellé</span>
                    </a>

                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherCouper"
                        v-on:click.prevent="couper" >
                        <img v-bind:src="images.imageCouper" />
                        <span class="bulle_a_droite">Déplacer vers le dossier privé</span>
                    </a>
                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherSupprimer"
                        v-on:click.prevent="supprimer" >
                        <img v-bind:src="images.imageSupprimer" />
                        <span class="bulle_a_droite">Supprimer</span>
                    </a>
                    <a class="icone_avec_infobulle" href="#"
                        v-if="afficherAjouterSelection"
                        v-on:click.prevent="ajouterSelection" >
                        <img v-bind:src="images.imageSelectionner" />
                        <span class="bulle_a_droite">Ajouter à la sélection</span>
                    </a>
                </p>

                <p class="visu_description" v-if="descriptionFormatee.length > 0">
                    {{ descriptionFormatee }}
                </p>
            </div>

            <p v-if="estUnLien">
                Vous naviguez sous un lien, <a href="#" @click.prevent="allerACibleLien()">aller au dossier cible</a>.
            </p>

            <p v-if="nombre_liens > 0">
                Ce dossier a {{ nombre_liens }} lien(s) pointant directement vers lui.
            </p>
        </div>

        <DossierModifierDossier
            v-if="etatEditionDossier"
            v-bind:dossier_courant="dossier_courant"
            v-on:cloture="evtFinEditionDossier($event)" />

        <hr id="sous_zone_identifiant"/>

        <DossierNouveauDossier
            v-if="etatNouveauDossier"
            v-bind:dossier_parent="dossier_courant"
            v-on:cloture="evtFinNouveauDossier($event)" />

        <DossierNouveauDocument
            v-if="etatNouveauDocument"
            v-bind:dossier_parent="dossier_courant"
            v-on:cloture="evtFinNouveauDocument($event)" />
    </div>
</template>

<script>
    import DossierNouveauDossier from './dossier_nouveau_dossier'
    import DossierModifierDossier from './dossier_modifier_dossier'
    import DossierNouveauDocument from './dossier_nouveau_document'

    import imageAjoutDossier from '../../../../assets/images/icones/folder_add.png'
    import imageAjoutDocument from '../../../../assets/images/icones/page_white_add.png'
    import imageEditer from '../../../../assets/images/icones/pencil.png'
    import imageCouper from '../../../../assets/images/icones/cut.png'
    import imageSupprimer from '../../../../assets/images/icones/bin_empty.png'
    import imageSelectionner from '../../../../assets/images/icones/link_go.png'

    import apiDossiers from '../../../api/dossiers'
    import bus from '../../helpers/bus'
    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    const etatsOperations = {
        VISUALISATION: 0,
        AJOUT_DOSSIER: 1,
        AJOUT_DOCUMENT: 2,
        EDITION_DOSSIER: 3
    }

    export default {
        data: () => {
            return {
                etat_courant: etatsOperations.VISUALISATION,
                nombre_liens: 0,
                images : {
                    imageAjoutDossier,
                    imageAjoutDocument,
                    imageEditer,
                    imageCouper,
                    imageSupprimer,
                    imageSelectionner
                }
            }
        },

        props: ['dossier_courant'],

        computed: {
            estUnLien: function() {
                return noeudHelpers.estUnLien(this.dossier_courant)
            },
            afficherAjouterDossier: function() {
                return this.dossier_courant?.droit_ecrire_contenu && !this.estUnLien
            },
            afficherAjouterDocument: function() {
                return this.afficherAjouterDossier // commun en l'état
            },
            afficherEditerLibelle: function() {
                return this.dossier_courant?.droit_ecrire
            },
            afficherCouper: function() {
                return this.dossier_courant?.droit_supprimer_document &&
                       this.dossier_courant?.id !== this.$store.state.racine?.id &&
                       ! this.estUnLien
            },
            afficherSupprimer: function() {
                return this.dossier_courant?.droit_supprimer_document &&
                       this.dossier_courant?.id !== this.$store.state.racine?.id
            },
            afficherAjouterSelection: function () {
                return this.dossier_courant?.id !== this.$store.state.racine?.id &&
                       ! this.estUnLien
            },
            etatVisualisation: function() {
                return this.etat_courant === etatsOperations.VISUALISATION
            },
            etatNouveauDossier: function() {
                return this.etat_courant === etatsOperations.AJOUT_DOSSIER
            },
            etatNouveauDocument: function() {
                return this.etat_courant === etatsOperations.AJOUT_DOCUMENT
            },
            etatEditionDossier: function() {
                return this.etat_courant === etatsOperations.EDITION_DOSSIER
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.dossier_courant)
            },
            descriptionFormatee: function() {
                return noeudHelpers.descriptionFormatee(this.dossier_courant)
            }
        },

        methods: {
            ajouterDossier: function() {
                this.etat_courant = etatsOperations.AJOUT_DOSSIER
            },
            ajouterDocument: function() {
                this.etat_courant = etatsOperations.AJOUT_DOCUMENT
            },
            editerlibelle: function() {
                this.etat_courant = etatsOperations.EDITION_DOSSIER
            },
            couper: async function() {
                let dossier_prive_id = this.$store.state.utilisateurCourant.dossier_prive_id
                if(!dossier_prive_id) {
                    // ne devrait pas arriver, mais on va éviter de perdre des
                    // document "dans la nature"...
                    alert("Vous n'avez pas de dossier privé.")
                    return
                }

                let parent_initial_id = this.dossier_courant.parent_id
                let dossier_courant_local = this.dossier_courant
                dossier_courant_local.parent_id = dossier_prive_id
                try {
                    await apiDossiers.modifierDossier(dossier_courant_local)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return
                }

                bus.deplacementDossier(dossier_courant_local, parent_initial_id)
                this._activerDossierParent(parent_initial_id)
                this.$store.dispatch('fetchContenuPrive')
            },
            supprimer: async function() {
                if(confirm("Voulez-vous vraiment supprimer ce dossier ?")) {
                    try
                    {
                        await apiDossiers.supprimerDossier(this.dossier_courant.id)
                    }
                    catch(error) {
                        erreursHelpers.alertMessageErreur(error)
                        return
                    }

                    bus.suppressionDossier(this.dossier_courant)
                    this._activerDossierParent(this.dossier_courant.parent_id)
                }
            },
            ajouterSelection: function() {
                this.$store.commit('addSelectionDossier', this.dossier_courant)
            },
            evtFinNouveauDossier: function(nouveau_dossier) {
                this.etat_courant = etatsOperations.VISUALISATION
                if(nouveau_dossier) {
                    bus.nouveauDossier(nouveau_dossier)
                }
            },
            evtFinEditionDossier: function(dossier_modifie) {
                this.etat_courant = etatsOperations.VISUALISATION
                if(dossier_modifie) {
                    bus.modificationDossier(dossier_modifie)
                    this.$store.commit('setDossierCourant', dossier_modifie)
                }
            },
            evtFinNouveauDocument: function(nouveau_document) {
                this.etat_courant = etatsOperations.VISUALISATION
                if(nouveau_document) {
                    bus.nouveauDocument(nouveau_document)
                }
            },
            allerACibleLien: function() {
                this.$store.dispatch('fetchCheminComplet', this.dossier_courant.noeud_reel_id)
            },
            _activerDossierParent: async function(parent_id) {
                let dossier_parent
                try {
                    dossier_parent = await apiDossiers.lireDossier(parent_id)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return
                }

                this.$store.commit('setDossierCourant', dossier_parent)
            }
        },

        watch: {
            dossier_courant: function() {
                this.etat_courant = etatsOperations.VISUALISATION
                
                if(noeudHelpers.estUnLien(this.dossier_courant)) {
                    this.nombre_liens = 0
                } else {
                    apiDossiers.getNombreLiens(this.dossier_courant.id)
                        .then((data) => {
                            this.nombre_liens = data
                        })
                        .catch((error) => {
                            this.nombre_liens = 0
                            erreursHelpers.alertMessageErreur(error)
                        })
                }
            }
        },

        components: {
            DossierNouveauDossier,
            DossierModifierDossier,
            DossierNouveauDocument
        }

    }
</script>
