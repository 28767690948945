import http from './http'

function getSource(sourceId) {
    let url = `sources/${encodeURIComponent(sourceId)}`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function getSourcesUtilisateur(utilisateurId) {
    let url = `utilisateurs/${encodeURIComponent(utilisateurId)}/sources`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function getContenuSourcesUtilisateur(utilisateurId, sourceId) {
    let url = `utilisateurs/${encodeURIComponent(utilisateurId)}/sources/${encodeURIComponent(sourceId)}/contenu`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

// le nom est une peut court, peux mieux faire...
function getDownloadUrlDocumentDansSourcesUtilisateur(utilisateurId, sourceId, nomFichier) {
    let nom_fichier_encode = encodeURINomFichier(nomFichier)
    // baseURL content déjà un '/' à la fin
    return `${http.defaults.baseURL}utilisateurs/${encodeURIComponent(utilisateurId)}/sources/${encodeURIComponent(sourceId)}/contenu/${nom_fichier_encode}`
}

function supprimeDocumentSourcesUtilisateur(utilisateurId, sourceId, nomFichier) {
    let nom_fichier_encode = encodeURINomFichier(nomFichier)
    let url = `utilisateurs/${encodeURIComponent(utilisateurId)}/sources/${encodeURIComponent(sourceId)}/contenu/${nom_fichier_encode}`

    return http.delete(url)
        .then(function(response) {
            return response.data
        })
}

function encodeURINomFichier(nomFichier) {
    return encodeURIComponent(nomFichier).replace(".", "%2E")
}

export default {
    getSource,
    getSourcesUtilisateur,
    getContenuSourcesUtilisateur,
    getDownloadUrlDocumentDansSourcesUtilisateur,
    supprimeDocumentSourcesUtilisateur
}
