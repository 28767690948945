import http from './http'

const cst_type_noeud = {
    DOSSIER: 0,
    DOCUMENT: 1
}

function getRacine() {
    return http.get('dossiers/racine')
                .then(function(response) {
                    return response.data
                })
}

function getNombreDossiersFils(parent_id) {
    let url = `dossiers/${encodeURIComponent(parent_id)}/dossiers/nombre`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
}

function getNombreDocumentsFils(parent_id) {
    let url = `dossiers/${encodeURIComponent(parent_id)}/documents/nombre`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
}

function getDossiersFils(parent_id,
                         prefixe,
                         page,
                         par_page) {
    let url = `dossiers/${encodeURIComponent(parent_id)}/dossiers`
    return _getFils(url, prefixe, page, par_page)
}

function getDocumentsFils(parent_id,
                         prefixe,
                         page,
                         par_page,
                         ordre_tri,
                         voir_supprimes) {
    let url = `dossiers/${encodeURIComponent(parent_id)}/documents`

    return _getFils(url, prefixe, page, par_page, ordre_tri, voir_supprimes, true)
}

function _getFils(url,
                  prefixe,
                  page,
                  par_page,
                  ordre_tri = null,
                  voir_supprimes = null,
                  details_documents = null) {
    let options = {
        details_utilisateurs: true,
        details_meta: false,
    }
    if(ordre_tri) {
        options.tri = ordre_tri
    }
    if(voir_supprimes) {
        options.supprimes = voir_supprimes
    }
    if(details_documents) {
        options.details_documents = details_documents
    }        
    return http.get(url,
                    {
                        params: {
                            prefixe: prefixe,
                            page: page,
                            par_page: par_page,
                            ...options
                        }
                    }
                )
                .then(function(response) {
                    let pagination = {
                        page: response.headers['pagination-page'],
                        par_page: response.headers['pagination-parpage'],
                        total_pages: response.headers['pagination-totalpages'],
                        total_items: response.headers['pagination-iotalitems']
                    }
                    return [response.data, pagination];
                })
}

function lireDossier(dossier_id) {
    let options = {
        details_utilisateurs: true,
        details_meta: true,
    }

    let url = `dossiers/${encodeURIComponent(dossier_id)}`

    return http.get(url, { params: { ...options }})
        .then(function(response) {
            return response.data
        })
}

function creerDossier(parent_id,
                      identifiant,
                      description) {

    let url = `dossiers/${encodeURIComponent(parent_id)}/dossiers`

    return http.post(url,
        {
            identifiant,
            description
        })
        .then(function(response) {
            return response.data
        })
}

function modifierDossier(dossier) {

    let url = `dossiers/${encodeURIComponent(dossier.id)}`

    return http.put(url,
            {
                ...dossier
            })
            .then(function(response) {
                return response.data
            })
}

function supprimerDossier(noeud_id) {
    let url = `dossiers/${encodeURIComponent(noeud_id)}`

    return http.delete(url)
    .then(function(response) {
        return response.data
    })
}

function getAncetres(noeud_id) {
    let url = `dossiers/${encodeURIComponent(noeud_id)}/ancetres`

    return http.get(url,
                    {
                        params: {
                            details_utilisateurs: true,
                            details_meta: true,
                        }
            })
            .then(function(response) {
                return response.data
            })
}

function creerLienDossier(noeud_id, cible_id, identifiant=null) {
    let url = `dossiers/${encodeURIComponent(noeud_id)}/liens/dossiers`
    return _creerLien(url, cible_id, identifiant)
}

function creerLienDocument(noeud_id, cible_id, identifiant=null) {
    let url = `dossiers/${encodeURIComponent(noeud_id)}/liens/documents`
    return _creerLien(url, cible_id, identifiant)
}

function _creerLien(url, cible_id, identifiant=null) {
    let params = { cible_id }
    if(identifiant) {
        params.identifiant = identifiant
    }

    return http.post(url, params)
                .then(function(response) {
                    return response.data
                })
}

function getNombreLiens(noeud_id) {
    let url = `dossiers/${encodeURIComponent(noeud_id)}/liens/nombre`

    return http.get(url)
                .then(function(response) {
                    return response.data.nombre_liens
                })
}

export default {
    getRacine,

    getNombreDossiersFils,
    getDossiersFils,
    getNombreDocumentsFils,
    getDocumentsFils,

    lireDossier,
    creerDossier,
    supprimerDossier,
    modifierDossier,
    getAncetres,

    creerLienDossier,
    creerLienDocument,
    getNombreLiens,
}
