<template>
    <table class="liste_dans_onglets liste_dans_onglets_navigation">
        <tbody>
            <tr>
                <th>{{ denomination }}</th>
                <th class="case_droit">Voir</th>
                <th class="case_droit">Lire</th>
                <th class="case_droit">Ecrire</th>
                <th class="case_droit">Ecrire contenu</th>
                <th class="case_droit">Supprimer document</th>
                <th class="case_droit">Lire doc. supprimé</th>
                <th class="case_droit">Ressusciter doc.</th>
            </tr>

            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <tr v-for="acl of acls">
                <td>{{ identifiant(acl) }}</td>
                <td class="case_droit"><span :class="class_droit(acl.droit_voir)">{{ libelle_etat_droit(acl.droit_voir) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_lire)">{{ libelle_etat_droit(acl.droit_lire) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_ecrire)">{{ libelle_etat_droit(acl.droit_ecrire) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_ecrire_contenu)">{{ libelle_etat_droit(acl.droit_ecrire_contenu) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_supprimer_document)">{{ libelle_etat_droit(acl.droit_supprimer_document) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_lire_supprime)">{{ libelle_etat_droit(acl.droit_lire_supprime) }}</span></td>
                <td class="case_droit"><span :class="class_droit(acl.droit_ressusciter)">{{ libelle_etat_droit(acl.droit_ressusciter) }}</span></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import apiNoeudsAcls from '../../../api/noeuds_acls'

    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    import _ from 'lodash'

    export default {
        data: () => {
            return {
                images: {

                }
            }
        },

        props: ['acls', 'concerne'],

        computed: {
            estGroupe: function() {
                return this.acls[0].groupe_id !== undefined
            },
            denomination: function() {
                return this.estGroupe ? 'Groupes' : 'Utilisateurs'
            }
        },

        methods: {
            identifiant: function(acl) {
                let id = this.estGroupe ? acl.groupe_id : acl.utilisateur_id
                let cible_acl = _.find(this.concerne, (c) => { return c.id === id })
                return this.estGroupe ? cible_acl?.libelle : cible_acl?.identifiant
            },
            libelle_etat_droit: function(droit_actif) {
                return droit_actif ? 'Oui' : 'Non'
            },
            class_droit: function(droit_actif) {
                return droit_actif ? 'booleen_vrai' : 'booleen_faux'
            }
        }

    }
</script>
