<template>
    <table class="liste_dans_onglets liste_dans_onglets_sources">
        <tbody>
            <tr>
                <th></th>
                <th></th>
                <th>{{ designation }}</th>
            </tr>
            <tr v-for="noeud of noeuds" :key="noeud.id">
                <td class="icone_dans_tableau">
                    <input type="checkbox" :value="noeud" v-model="selection" @click="changeSelection(noeud)">
                </td>
                <td class="icone_dans_tableau">
                    <img :src="icone(noeud)" />
                </td>
                <td>
                    {{ identifiantFormate(noeud) }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'

    import imgageDossier from '../../../../assets/images/icones/documents/folder.png'

    import _ from 'lodash'

    export default {
        data: () => {
            return {
                selection: [],
                images: {
                    imgageDossier
                }
            }
        },

        props: ['designation', 'noeuds'],


        methods: {
            identifiantFormate: function(noeud) {
                return noeudHelpers.identifiantFormate(noeud, 50)
            },
            icone: function(noeud) {
                if(noeudHelpers.estUnDossier(noeud)) {
                    return this.images.imgageDossier
                }

                return documentHelpers.iconeTypeDocument(document)
            },
            changeSelection: function (noeud) {
                // si c'est un ajout, le noeud n'est pas encore dans la selection
                // du composant (il n'est ajouté par vue qu'après l'evenement).
                let ajout = !_.find(this.selection, noeud) //? true : false
                this.$emit('maj_selection', {noeud, ajout})
            }
        }
    }
</script>