<template>
    <div class="required_vue_root">
        <br/>

        <p>
            <span>Libellé du {{ texteTypeNoeud }}</span>
            <strong>{{ identifiantFormate }}</strong>
        </p>

        <hr/>

        <DetailsDroitsEdition
            :noeud="noeud_courant"
            :acls="acls_parametres"
            :groupes="groupes"
            :utilisateurs="utilisateurs"
            @fin_edition="finEdition"
            v-if="etatEdition"
        />

        <div class="artificial_container"
             v-if="!etatEdition">

            <div class="artificial_container"
                v-if="estRacine">
                <p>
                    Ceci est la racine de la GED, les droits sont paramétrés de manière à ce que
                    tout le monde puisse en voir le contenu, mais seul l'administrateur peut y
                    apporter des modifications.
                </p>
                <p>
                    Les droits de la racine ne peuvent être modifiés.
                </p>
            </div>

            <div class="artificial_container"
                v-else>
                <p v-if="origineAclRacine">
                    Cet élément hérite des droits de la racine de la GED.
                </p>

                <p v-if="!origineAclRacine && origineAclParent">
                    Cet élément hérite des droits paramétré sur un dossier parent.
                </p>

                <p v-if="existeAcls">
                    Les droits ont été explicitement paramétrés pour cet élément.
                </p>

                <p v-if="editionPossible">
                    <a href="#" @click.prevent="editerAcls">{{ texteLienEdition }}</a>
                </p>

                <hr/>

                <div class="artificial_container"
                    v-if="!origineAclRacine">

                    <p>Droits en vigueur :</p>

                    <DetailsDroitsAcls
                        :acls="acls_effectifs.groupes"
                        :concerne="groupes"
                        v-if="acls_effectifs.groupes.length > 0"
                    />
                    <p v-else>
                        Aucun paramétrage de droits pour les groupes.
                    </p>

                    <DetailsDroitsAcls
                        :acls="acls_effectifs.utilisateurs"
                        :concerne="utilisateurs"
                        v-if="acls_effectifs.utilisateurs.length > 0"
                    />
                    <p v-else>
                        Aucun paramétrage de droits pour les utilisateurs.
                    </p>

                    <br/>
                    <hr/>
                    <p>
                        <em>Les utilisateurs ayant les droits d'administration ont toujours accès à l'ensemble des dossiers et documents.</em>
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import DetailsDroitsAcls from './details_droits_acls'
    import DetailsDroitsEdition from './details_droits_edition'

    import apiNoeudsAcls from '../../../api/noeuds_acls'
    import apiGroupes from '../../../api/groupes'
    import apiUtilisateurs from '../../../api/utilisateurs'

    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    const etatsOperations = {
        VISUALISATION: 0,
        EDITION: 1
    }

    export default {
        data: () => {
            return {
                acls_parametres: {
                    groupes: [],
                    utilisateurs: []
                },
                acls_origine_id: null,
                acls_effectifs: {
                    groupes: [],
                    utilisateurs: []
                },
                groupes: [],
                utilisateurs: [],
                etat_courant: etatsOperations.VISUALISATION
            }
        },

        created: async function() {
            await this._initDonnees();
        },

        computed: {
            noeud_courant: function() {
                return this.$store.getters.noeudCourant
            },
            estRacine: function() {
                return noeudHelpers.estRacine(this.noeud_courant)
            },
            estUnLien: function() {
                return noeudHelpers.estUnLien(this.noeudCourant)
            },
            editionPossible: function() {
                let utilisateurCourant = this.$store.state.utilisateurCourant
                return utilisateurCourant.administrateur && !this.estUnLien
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.noeud_courant)
            },
            existeAcls: function() {
                return this.acls_parametres.groupes.length > 0 ||
                       this.acls_parametres.utilisateurs.length > 0
            },
            origineAclRacine: function() {
                return this.acls_origine_id === this.$store.state.racine.id
            },
            origineAclParent: function() {
                return this.acls_origine_id !== this.noeud_courant.id
            },
            texteLienEdition: function() {
                if(this.existeAcls) {
                    return 'Modifier des droits propres à cet élément'
                } else {
                    return 'Créer des droits propres à cet élément'
                }
            },
            texteTypeNoeud: function() {
                if(noeudHelpers.estUnDossier(this.noeud_courant)) {
                    return 'dossier'
                } else {
                    return 'document'
                }
            },
            etatEdition: function() {
                return this.etat_courant === etatsOperations.EDITION
            }
        },

        methods: {
            editerAcls: async function() {
                // On s'assure que les infos sont à jours avant édition
                await this._initDonnees()
                this.etat_courant = etatsOperations.EDITION
            },
            finEdition: async function() {
                await this._initDonnees()
            },
            _initDonnees: async function() {
                this.etat_courant = etatsOperations.VISUALISATION
                await this._chargeDroits()
                await this._chargeGroupes()
                await this._chargeUtilisateurs()
            },
            _chargeDroits: async function() {
                this.acls_parametres.groupes = []
                this.acls_parametres.utilisateurs = []
                this.acls_effectifs.groupes = []
                this.acls_effectifs.utilisateurs = []

                let api_function = null
                if(noeudHelpers.estUnDossier(this.noeud_courant)) {
                    api_function = apiNoeudsAcls.getAclsDossier
                } else {
                    api_function = apiNoeudsAcls.getAclsDocument
                }
                let infos_acls = await api_function(this.noeud_courant.id)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                this.acls_parametres.groupes = infos_acls.groupes
                this.acls_parametres.utilisateurs = infos_acls.utilisateurs
                this.acls_origine_id = infos_acls.noeud_acls_id
                // Si les droits viennent d'un parent, charger les droits
                // en complément pour savoir lesquels sont en vigueur.
                if(this.acls_origine_id !== this.noeud_courant.id) {
                    await this._chargeDroitsAncetre(this.acls_origine_id)
                } else {
                    this.acls_effectifs.groupes = this.acls_parametres.groupes
                    this.acls_effectifs.utilisateurs = this.acls_parametres.utilisateurs
                }
            },
            _chargeDroitsAncetre: async function(dossier_id) {
                let infos_acls = await apiNoeudsAcls.getAclsDossier(dossier_id)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                this.acls_effectifs.groupes = infos_acls.groupes
                this.acls_effectifs.utilisateurs = infos_acls.utilisateurs
            },
            _chargeGroupes: async function() {
                this.groupes = await apiGroupes.getGroupes()
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
            },
            _chargeUtilisateurs: async function() {
                this.utilisateurs = await apiUtilisateurs.getUtilisateurs()
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
            },
        },

        watch: {
            noeud_courant: async function() {
                await this._initDonnees()
            }
        },

        components: {
            DetailsDroitsAcls,
            DetailsDroitsEdition,
        }
    }
</script>
