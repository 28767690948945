function limiteTailleChaine(chaine, taille_maxi) {
    if(!chaine) {
        return ''
    }

    if(chaine.length <= taille_maxi) {
        return chaine
    }

    return chaine.substring(0, taille_maxi - 2) + '…' 
}

function LFtoBR(chaine) {
    return chaine.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export default {
    limiteTailleChaine,
    LFtoBR
}