<template>
    <Onglets :listeOnglets="listeOnglets"
             :classes="classes_onglets"
             :min_height="'50'"
             :max_height="'200px'"
    />
</template>

<script>
    import Onglets from '../utilitaires/onglets'
    import DossierPrive from './dossier_prive'
    import Selection from './selection'
    import Source from './source'

    import sourcesApi from '../../../api/sources'

    import erreursHelpers from '../../helpers/erreurs'

    export default {
        data: () => {
            return {
                sources: [],
                classes_onglets: {
                    exterieur: 'onglets_sources_exterieur',
                    interieur: 'onglets_sources_interieur',
                    actif: 'onglets_sources_actif',
                    inactif: 'onglets_sources_inactif'
                }
            }
        },

        created: async function() {
            await this.chargeSources()
        },

        computed: {
            listeOnglets: function() {
                let utilisateurCourant = this.$store.state.utilisateurCourant

                let liste = [
                    { libelle: 'Privé', composant: DossierPrive },
                    { libelle: 'Sélection', composant: Selection }
                ]

                for(let source of this.sources) {
                    let onglet_source = {
                        libelle: source.identifiant,
                        composant: Source,
                        props: { sourceId: source.id }
                    }
                    liste.push(onglet_source)
                }

                return liste
            },
            utilisateurCourant: function() {
                return this.$store.state.utilisateurCourant
            }
        },

        methods: {
            chargeSources: async function() {
                let sources = []
                let sources_utilisateur

                try
                {
                    sources_utilisateur = await sourcesApi.getSourcesUtilisateur(this.utilisateurCourant.id)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    this.sources = []
                    return
                }

                for(let source_utilisateur of sources_utilisateur) {
                    let source
                    try {
                        source = await sourcesApi.getSource(source_utilisateur.source_id)
                    }
                    catch(error) {
                        erreursHelpers.alertMessageErreur(error)
                        continue
                    }
                    
                    sources.push({
                        id: source.id,
                        identifiant: source.identifiant
                    })
                }

                this.sources = sources
            }
        },

        components: {
            Onglets
        }
    }
</script>

