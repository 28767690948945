import http from './http'

function getUtilisateurs() {
    let url = `utilisateurs`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

function getUtilisateur(utilisateurId) {
    let url = `utilisateurs/${encodeURIComponent(utilisateurId)}`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

export default {
    getUtilisateurs,
    getUtilisateur
}