<template>
    <Onglets :listeOnglets="listeOnglets"
             :classes="classes_onglets"
             :min_height="'600px'"
             :max_height="'600px'"
             v-if="noeudCourant" />
</template>

<script>
    import Onglets from '../utilitaires/onglets'
    import DetailsContenu from './details_contenu'
    import DetailsMetadonnees from './details_metadonnees'
    import DetailsDroits from './details_droits'
    import DetailsParamMetadonnees from './details_param_metadonnees'
    import DetailsStockage from './details_stockage'

    import noeudHelpers from '../../helpers/noeud'

    export default {
        data: () => {
            return {
                classes_onglets: {
                    exterieur: 'onglets_larges_exterieur',
                    interieur: 'onglets_larges_interieur',
                    actif: 'onglets_larges_actif',
                    inactif: 'onglets_larges_inactif'
                }
            }
        },

        computed: {
            noeudCourant: function() {
                return this.$store.getters.noeudCourant
            },

            estDocument: function() {
                return noeudHelpers.estUnDocument(this.noeudCourant)
            },

            estLien: function() {
                return noeudHelpers.estUnLien(this.noeudCourant)
            },

            listeOnglets: function() {
                let utilisateurCourant = this.$store.state.utilisateurCourant

                let liste = [
                            { libelle: 'Contenu', composant: DetailsContenu },
                            { libelle: 'Méta.', composant: DetailsMetadonnees }
                        ]

                // Le chargement étant async, ces éléments peuvent ne pas être
                // disponibles à l'ouverture.
                if(!utilisateurCourant || !this.noeudCourant) {
                    return liste
                }

                if(this.estLien) {
                    return liste
                }

                liste = liste.concat([
                    { libelle: 'Droits', composant: DetailsDroits }
                ])

                if(utilisateurCourant.administrateur === false || this.estDocument) {
                    return liste
                }

                liste = liste.concat([
                    { libelle: 'Paramétrage méta.', composant: DetailsParamMetadonnees },
                    { libelle: 'Stockage', composant: DetailsStockage }
                ])
                return liste
            }
        },

        components: {
            Onglets
        }
    }
</script>
