import http from './http'

function getGroupes() {
    let url = `admin/groupes`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

function getGroupe(groupeId) {
    let url = `admin/groupes/${encodeURIComponent(groupeId)}`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
} 

export default {
    getGroupes,
    getGroupe
}