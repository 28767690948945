<template>
    <div class="required_vue_root">
        <ErreursSaisie v-if="erreurs" v-bind:liste_erreurs="erreurs" />

        <p>
            <span class="lib_infos_document">Libellé</span>
            <input class="type_texte" maxlength="250" size="40" type="text" placeholder="nom du document"
                   v-model="identifiant" />
        </p>

        <p>
            <span class="lib_infos_document">Origine/Exp.</span>
            <input class="type_texte" maxlength="64" size="40" type="text" placeholder="origine du document"
                   v-model="origine" />
        </p>

        <p>
            <span class="lib_infos_document">Important/Urgent</span>
            <input type="checkbox" v-model="important">
        </p>

        <p>
            <span class="lib_infos_document">Description</span>
            <textarea cols="40" rows="10" v-model="description"/>
        </p>

        <p>
            <span class="lib_infos_document">Mots clés</span>
            <textarea cols="40" rows="3" v-model="mots_cles"/>
        </p>

        <p>
            <span class="lib_infos_document">Date d'origine</span>
            <flat-pickr v-model="str_horodatage_origine" :config="configDateTimePicker"></flat-pickr>
        </p>

        <p>
            &nbsp;
            <a href="#" @click.prevent="clickModifierDocument"><strong>Modifier le document</strong></a>&nbsp;
            <a href="#" @click.prevent="clickAnnuler">Annuler</a>
        </p>
    </div>
</template>

<script>
    import ErreursSaisie from '../utilitaires/erreurs_saisie'
    import FlatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'

    import apiDocuments from '../../../api/documents'
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'
    import erreursHelpers from '../../helpers/erreurs'
    import dateTimeHelpers from '../../helpers/date_time'

    const etatsOperations = {
        VISUALISATION: 0,
        EDITION_DOCUMENT: 1
    }

    export default {
        data: () => {
            return {
                erreurs: null,
                identifiant: null,
                origine: null,
                important: null,
                description: null,
                mots_cles: null,
                str_horodatage_origine: null,
            }
        },

        props: ['document_courant'],

        created() {
            this.identifiant = this.document_courant.identifiant
            this.origine = this.document_courant.origine
            this.important = this.document_courant.important
            this.description = this.document_courant.description
            this.mots_cles = noeudHelpers.motsClesArrayToString(this.document_courant.mots_cles)
            this.str_horodatage_origine = dateTimeHelpers.dateToDDMMYYYY(dateTimeHelpers.isoToDate(this.document_courant.horodatage_origine))
        },

        computed: {
            dateOrigine: function() {
                if(!this.str_horodatage_origine) {
                    return null
                }
                return dateTimeHelpers.ddmmyyyyToDate(this.str_horodatage_origine)
            },
            configDateTimePicker: function() {
                return dateTimeHelpers.defaultFlatPickrConfig()
            }
        },

        methods: {
            clickModifierDocument: function() {
                if(!this._validation()) {
                    return
                }
                this.erreurs = null
                apiDocuments.lireDocument(this.document_courant.id)
                    .then((document_relu) => {
                        document_relu.identifiant = this.identifiant
                        document_relu.origine = this.origine
                        document_relu.important = this.important
                        document_relu.description = this.description
                        document_relu.mots_cles = noeudHelpers.motsClesStringToArray(this.mots_cles)
                        document_relu.horodatage_origine = this.dateOrigine.toISOString()
                        return apiDocuments.modifierDocument(document_relu)
                    })
                    .then((document_modifie) => {
                        this.$emit('cloture', document_modifie)
                    })
                    .catch((error) => {
                        let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                        if(data) {
                            this.erreurs = data.error
                            console.log(this.erreurs)
                        }
                    })
            },
            clickAnnuler: function() {
                this.$emit('cloture', null)
            },
            _validation: function() {
                let erreurs = []

                if(!(this.identifiant?.length > 0)) {
                    erreurs.push("L'identifiant est obligatoire")
                }

                if(!this.str_horodatage_origine) {
                    erreurs.push("La date d'origine est obligatoire")
                } else if(!this.dateOrigine) {
                    erreurs.push("La date d'origine est invalide (JJ/MM/AAAA)")
                } else if(this.dateOrigine < dateTimeHelpers.cstDateMini || this.dateOrigine > dateTimeHelpers.cstDateMaxi) {
                    erreurs.push("La date d'origine est incorrecte")
                }

                if(erreurs.length === 0) {
                    this.erreurs = null
                    return true
                } else {
                    this.erreurs = erreurs
                    return false
                }
            }
        },

        components: {
            ErreursSaisie,
            FlatPickr,
        }
    }
</script>
