<template>
    <div class="required_vue_root">
        <p>
            <select v-model="cible_selectionnee_id">
                <option disabled>Choisissez...</option>
                <option v-for="cible of ciblesHorsAcls" :key="cible.id" :value="cible.id">{{ identifiant(cible) }}</option>
            </select>
            &nbsp;&nbsp;
            <a href="#" @click.prevent="ajouterCible">Ajouter</a>
        </p>

        <table class="liste_dans_onglets liste_dans_onglets_navigation"
               v-if="acls.length > 0">
            <tbody>
                <tr>
                    <th class="icone_dans_tableau">&nbsp;</th>
                    <th>{{ denomination }}</th>
                    <th class="case_droit">Voir</th>
                    <th class="case_droit">Lire</th>
                    <th class="case_droit">Ecrire</th>
                    <template v-if="!est_document">
                        <th class="case_droit">Ecrire contenu</th>
                        <th class="case_droit">Supprimer document</th>
                        <th class="case_droit">Lire doc. supprimé</th>
                        <th class="case_droit">Ressusciter doc.</th>
                    </template>
                </tr>

                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <tr v-for="acl of acls">
                    <td><a href="#" @click.prevent="supprimerAcl(acl)"><img :src="images.imageEffacer"></a></td>
                    <td>{{ identifiant_acl(acl) }}</td>
                    <td class="case_droit"><input type="checkbox" v-model="acl.droit_voir"></td>
                    <td class="case_droit"><input type="checkbox" v-model="acl.droit_lire"></td>
                    <td class="case_droit"><input type="checkbox" v-model="acl.droit_ecrire"></td>
                    <template v-if="!est_document">
                        <td class="case_droit"><input type="checkbox" v-model="acl.droit_ecrire_contenu"></td>
                        <td class="case_droit"><input type="checkbox" v-model="acl.droit_supprimer_document"></td>
                        <td class="case_droit"><input type="checkbox" v-model="acl.droit_lire_supprime"></td>
                        <td class="case_droit"><input type="checkbox" v-model="acl.droit_ressusciter"></td>
                    </template>
                </tr>
            </tbody>
        </table>
        <p v-else>Aucun paramétrage de droits pour les {{ denomination.toLowerCase() }}, ajoutez-en un pour définir des droits.</p>
    </div>
</template>

<script>
    import imageEffacer from '../../../../assets/images/icone_effacer.png'

    import _ from 'lodash'

    export default {
        data: () => {
            return {
                cible_selectionnee_id: null,
                images: {
                    imageEffacer,
                }
            }
        },

        props: ['acls', 'concerne', 'est_groupe', 'est_document'],

        computed: {
            denomination: function() {
                return this.est_groupe ? 'Groupes' : 'Utilisateurs'
            },
            ciblesHorsAcls: function() {
                return _.filter(this.concerne, (c) => {
                    return !_.find(this.acls, (acl) => {
                        return this.getId(acl) === c.id
                    })
                })
            },
        },

        methods: {
            getId: function(acl) {
                if(this.est_groupe) {
                    return acl.groupe_id
                } else {
                    return acl.utilisateur_id
                }
            },
            identifiant: function(cible) {
                return this.est_groupe ? cible?.libelle : cible?.identifiant
            },
            identifiant_acl: function(acl) {
                let id = this.est_groupe ? acl.groupe_id : acl.utilisateur_id
                let cible_acl = _.find(this.concerne, (c) => { return c.id === id })
                return this.est_groupe ? cible_acl?.libelle : cible_acl?.identifiant
            },
            ajouterCible: function() {
                if(!this.cible_selectionnee_id) {
                    return
                }
                let acl = {
                    droit_voir: false,
                    droit_lire: false,
                    droit_ecrire: false,
                    droit_ecrire_contenu: false,
                    droit_supprimer_document: false,
                    droit_lire_supprime: false,
                    droit_ressusciter: false
                }
                if(this.est_groupe) {
                    acl.groupe_id = this.cible_selectionnee_id
                } else {
                    acl.utilisateur_id = this.cible_selectionnee_id
                }
                this.acls.push(acl)
            },
            supprimerAcl: function(acl) {
                // ne pas utiliser _.filter , car il ne faut pas remplacer
                // this.acls, seulement en modifier le contenu !
                let cible_id = this.getId(acl)
                let index = _.indexOf(this.acls, (a) => {
                    return this.getId(a) === cible_id
                })
                this.acls.splice(index, 1)
            }
        }

    }
</script>
