import http from './http'

function rechercheDossiers(criteres, offset, par_page) {
    let url = `dossiers/fulltext`

    return http.get(url,
                    {
                        params: {
                            offset,
                            par_page,
                            ...criteres
                        }
                    })
                .then(function(response) {
                    return response.data
                })
} 

function rechercheDocuments(criteres, offset, par_page) {
    let url = `documents/fulltext`

    return http.get(url,
                    {
                        params: {
                            offset,
                            par_page,
                            ...criteres
                        }
                    })
                .then(function(response) {
                    return response.data
                })
}

export default {
    rechercheDossiers,
    rechercheDocuments
}