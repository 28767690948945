<template>
    <div id="explorateur" style="height: 610px;">
        <div id="explorateur_arbre">
            <ul class="noeud_ouvert"
                v-if="this.racine !== null">
                <NoeudArborescence
                    :noeud="racine"
                    :force_chemin="force_chemin"
                />
            </ul>
        </div>
    </div>
</template>

<script>
    import NoeudArborescence from './noeud_arborescence';

    export default {
        data: () => {
            return {
            }
        },

        computed: {
            racine() {
                return this.$store.state.racine
            },
            force_chemin() {
                let ancetres_dossier_courant = this.$store.state.ancetres_dossier_courant
                return ancetres_dossier_courant && ancetres_dossier_courant.length > 0
            }
        },


        components: {
            NoeudArborescence,
        }
    }
</script>