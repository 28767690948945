<template>
    <div class="required_vue-root">
        <p v-if="existe_contenu">
            <a href="#" @click.prevent="deplacerVersDossierCourant">Déplacer les documents sélectionnés vers le dossier courant</a>
        </p>

        <p v-else>
            Pour déplacer des documents ou dossiers vers le dossier privé, utilisez l'icône
            <img :src="images.imageCouper" /> sur l'élément concerné.
            <br/>
            Attention, ces éléments ne seront plus visibles par tous tant qu'ils seront dans votre dossier privé.
        </p>

        <ListeNoeuds
            v-if="dossiers.length > 0"
            :designation="'Dossiers'"
            :noeuds="dossiers"
            @maj_selection="miseAJourSelection(selection.dossiers, $event)"
        />

        <ListeNoeuds
            v-if="documents.length > 0"
            :designation="'Documents'"
            :noeuds="documents"
            @maj_selection="miseAJourSelection(selection.documents, $event)"
        />
    </div>
</template>

<script>
    import ListeNoeuds from './liste_noeuds'

    import apiDocuments from '../../../api/documents'
    import apiDossiers from '../../../api/dossiers'

    import erreursHelpers from '../../helpers/erreurs'
    import noeudHelpers from '../../helpers/noeud'
    import bus from '../../helpers/bus'

    import imageCouper from '../../../../assets/images/icones/cut.png'

    export default {
        data: () => {
            return {
                selection: {
                    dossiers: [],
                    documents: []
                },
                images: {
                    imageCouper,
                }
            }
        },

        computed: {
            dossiers: function() {
                return this.$store.state.prive.dossiers
            },
            documents: function() {
                return this.$store.state.prive.documents
            },
            dossier_prive_id: function() {
                return this.$store.state.utilisateurCourant.dossier_prive_id
            },
            existe_contenu: function() {
                return this.dossiers.length > 0 || this.documents.length > 0
            }
        },

        methods: {
            deplacerVersDossierCourant: async function() {
                if(!this._verifieSelection()) {
                    return
                }

                let dossier_courant_id = this.$store.state.dossier_courant.id

                // Mise à jour via l'API
                await Promise.all(
                    _.map(this.selection.documents, (un_document) => {
                        un_document.parent_id = dossier_courant_id
                        return apiDocuments.modifierDocument(un_document)
                    })
                )
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })

                await Promise.all(
                    _.map(this.selection.dossiers, (un_dossier) => {
                        un_dossier.parent_id = dossier_courant_id
                        return apiDossiers.modifierDossier(un_dossier)
                    })
                )
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })

                // Notifications internes
                for(let un_document of this.selection.documents) {
                    bus.deplacementDocument(un_document, this.dossier_prive_id)
                }

                for(let un_dossier of this.selection.dossiers) {
                    bus.deplacementDossier(un_dossier, this.dossier_prive_id)
                }

                this.selection.dossiers.splice(0, this.selection.dossiers.length)
                this.selection.documents.splice(0, this.selection.documents.length)
                this.$store.dispatch('fetchContenuPrive')
            },
            miseAJourSelection: function(selection, {noeud, ajout}) {
                if(ajout) {
                    selection.push(noeud)
                } else {
                    let index = selection.indexOf(noeud)
                    if(index > -1) {
                        selection.splice(index, 1)
                    }
                }
            },
            _verifieSelection: function() {
                if(this.selection.dossiers.length === 0 && this.selection.documents.length === 0) {
                    alert("Il faut sélectionner des éléments avant de les déplacer.")
                    return false
                }
                return true
            }
        },

        components: {
            ListeNoeuds,
        }
    }
</script>