import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

import apiDossiers from '../../api/dossiers.js'
import apiDocuments from '../../api/documents.js'
import apiUtilisateurs from '../../api/utilisateurs.js'

import _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        utilisateurCourant: null,
        racine: null,
        dossier_courant: null,
        document_courant: null,
        ancetres_dossier_courant: null,
        prive: {
            dossiers: [],
            documents: []
        },
        selection: {
            dossiers: [],
            documents: []
        }
    },

    getters:  {
        estInitialise: state => {
            return state.racine && state.utilisateurCourant
        },
        noeudCourant: state => {
            return state.document_courant ? state.document_courant : state.dossier_courant
        }
    },

    mutations: {
        setInitialise(state, est_initialise) {
            state.est_initialise = est_initialise
        },
        setUtilisateurCourant(state, utilisateur) {
            state.utilisateurCourant = utilisateur
        },

        setRacine(state, racine) {
            state.racine = racine
        },
        setDossierCourant(state, dossier_courant) {
            state.dossier_courant = dossier_courant
            // une sélection de dossier (devenant le nouveau dossier courant) va
            // avoir pour conséquence qu'il n'y a plus de document courant.
            state.document_courant = null
        },
        setDocumentCourant(state, document_courant) {
            state.document_courant = document_courant
        },
        setAncetresDossierCourant(state, ancetres_dossier_courant) {
            state.ancetres_dossier_courant = ancetres_dossier_courant
        },
        setPriveDossiers(state, dossiers) {
            state.prive.dossiers = dossiers
        },
        setPriveDocuments(state, documents) {
            state.prive.documents = documents
        },
        addSelectionDossier(state, dossier) {
            let existe = _.find(state.selection.dossiers, (n) => { return n.id === dossier.id })
            if(!existe) {
                state.selection.dossiers.push(dossier)
            }
        },
        addSelectionDocument(state, document) {
            let existe = _.find(state.selection.documents, (n) => { return n.id === document.id })
            if(!existe) {
                state.selection.documents.push(document)
            }
        },
        removeSelectionDossier(state, dossier) {
            let index = _.findIndex(state.selection.dossiers, (n) => { return n.id === dossier.id })
            if(index >= 0) {
                state.selection.dossiers.splice(index, 1)
            }
        },
        removeSelectionDocument(state, document) {
            let index = _.findIndex(state.selection.documents, (n) => { return n.id === document.id })
            if(index >= 0) {
                state.selection.documents.splice(index, 1)
            }
        }
    },

    actions: {
        async initStore(context, utilisateurCourantId) {
            await context.dispatch('fetchUtilisateurCourant', utilisateurCourantId);
            await context.dispatch('fetchRacine')
            await context.dispatch('fetchContenuPrive')
        },
        async fetchRacine(context) {
            let data = await apiDossiers.getRacine()
                .catch(error => {
                    console.log(error)
                })
            context.commit('setRacine', data)
            context.commit('setDossierCourant', this.state.racine)
        },
        async fetchUtilisateurCourant(context, utilisateurId) {
            let data = await apiUtilisateurs.getUtilisateur(utilisateurId)
                .catch(error => {
                    console.log(error)
                    return
                })
                context.commit('setUtilisateurCourant', data)
        },
        // permet de déclencher la 'teleportation' vers le noeud indiqué
        // dans l'arborescene.
        async fetchCheminCompletDossier(context, noeud_id) {
            await _fetchCheminComplet(context, noeud_id, false)
        },
        async fetchCheminCompletDocument(context, noeud_id) {
            await _fetchCheminComplet(context, noeud_id, true)
        },
        // charge le contenu du dossier privé de l'utilisateur.
        async fetchContenuPrive(context) {
            let dossier_prive_id = this.state.utilisateurCourant.dossier_prive_id
            let [dossiers, _p1] = await apiDossiers.getDossiersFils(dossier_prive_id, '', 0, 200)
                                .catch((error) => {
                                    console.log(error)
                                    context.commit('setPriveDossiers', [])
                                })
            context.commit('setPriveDossiers', dossiers)

            let [documents, _p2] = await apiDossiers.getDocumentsFils(dossier_prive_id, '', 0, 200)
                                .catch((error) => {
                                    console.log(error)
                                    context.commit('setPriveDocuments', [])
                                })
            context.commit('setPriveDocuments', documents)
        }
    }
})

async function _fetchCheminComplet(context, noeud_id, flag_document) {
    let api = flag_document ? apiDocuments : apiDossiers
    let data = await api.getAncetres(noeud_id)
        .catch(error => {
            console.log(error)
            return
        })

        let dossier_courant = null
        let ancetres_dossier_courant = null
        if(!flag_document) {
            dossier_courant = data[data.length - 1]
            ancetres_dossier_courant = data
            context.commit('setDossierCourant', dossier_courant)
        } else {
            let document_courant = data[data.length - 1]
            dossier_courant = data[data.length - 2]
            ancetres_dossier_courant = data
            context.commit('setDossierCourant', dossier_courant)
            context.commit('setDocumentCourant', document_courant)
        }
        context.commit('setAncetresDossierCourant', ancetres_dossier_courant)
}