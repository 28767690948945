<template>
    <div class="required_vue_root">

        <Pagination v-if="paginationRequise"
                    v-bind:prefixe_initial="''"
                    v-bind:page="pagination.page"
                    v-bind:total_pages="pagination.total_pages"
                    v-on:change_prefixe="changePrefixe($event)"
                    v-on:change_page="changePage($event)"
        />

        <p class="choix_ordre_tri">
            Trier les documents par &nbsp;
            <select v-model="ordre_tri">
                <option value="+identifiant">libellés croissants</option>
                <option value="-identifiant">libellés décroissants</option>
                <option value="+date_creation">dates croissantes</option>
                <option value="-date_creation">dates décroissantes</option>
            </select>
        </p>

        <p class="choix_ordre_tri">
            Afficher les documents supprimés &nbsp;
            <select v-model="voir_supprimes">
                <option value="0">Non</option>
                <option value="1">Oui</option>
                </select>
        </p>

        <p>
            <a href="#" @click.prevent="clickDeplacerVersDossierPrive">
                Déplacer les documents sélectionnés vers le dossier privé
            </a>
        </p>

        <p>
            Marque les documents sélectionnés comme :
            <a href="#" @click.prevent="clickMarquerLu">déjà lu</a>
             /
            <a href="#" @click.prevent="clickMarquerNonLu">non lu</a>
        </p>

        <p v-if="documents && documents.length <= 0">Ce dossier ne contient aucun document</p>

        <table v-if="documents && documents.length > 0"
               id="liste_documents_du_dossier"
               class="liste_dans_onglets liste_dans_onglets_navigation">
            <tbody>
                <tr>
                    <th colspan="4">Actions</th>
                    <th>Libellé</th>
                    <th>Créé par</th>
                    <th>Créé le</th>
                    <th></th>
                </tr>

                <tr v-for="document in documents" :key="document.id">
                    <td class="icone_dans_tableau">
                        <input type="checkbox" :value="document" v-model="documents_selectionnes"/>
                    </td>

                    <td class="icone_dans_tableau">
                        <a href="#" class="icone_avec_infobulle"
                            v-if="peutLireDocument(document)"
                            @click.prevent="voirDetailsDocument(document)">
                            <img v-bind:src="images.imageLoupe" />
                            <span class="bulle_a_droite">Voir les détails</span>
                        </a>
                    </td>

                    <td class="icone_dans_tableau">
                        <img alt="Pas_important" v-bind:src="iconeImportance(document)">
                    </td>

                    <td class="icone_dans_tableau">
                        <img v-bind:src="iconeTypeDocument(document)">
                    </td>

                    <td>
                        <a v-if="peutLireDocument(document)"
                           v-bind:href="downloadDocumentUrl(document)" target="_blank"
                           class="lien_vers_document">
                            <span :class="classIdentifiant(document)">
                                {{ identifiantFormate(document) }}
                            </span>
                        </a>
                        <span v-else :class="classEstSupprime(document)">{{ identifiantFormate(document) }}</span>
                    </td>

                    <td>
                        {{ document.cree_par.identifiant }}
                    </td>

                    <td class="colonne_date">
                        {{ dateCreation(document) }}
                    </td>

                    <td class="icone_dans_tableau">
                        <a href="#" class="icone_avec_infobulle"
                            v-if="peutSupprimer(document)"
                            @click.prevent="clickSupprimer(document)">
                            <img v-bind:src="images.imageSupprimer"
                            ><span class="bulle_a_gauche">Supprimer le document</span>
                        </a>
                        <a href="#" class="icone_avec_infobulle"
                            v-if="peutRessusciter(document)"
                            @click.prevent="clickRessusciter(document)">
                            <img v-bind:src="images.imageRessusciter">
                            <span class="bulle_a_gauche">Rescussiter le document</span>
                        </a>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Pagination from '../utilitaires/pagination'

    import imageLoupe from '../../../../assets/images/icones/magnifier.png'
    import imageSupprimer from '../../../../assets/images/icones/bin_empty.png'
    import imageRessusciter from '../../../../assets/images/icones/wand.png'

    import apiDossiers from '../../../api/dossiers.js'
    import apiDocument from '../../../api/documents.js'
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'
    import erreursHelpers from '../../helpers/erreurs'
    import dateTimeHelpers from '../../helpers/date_time'
    import bus from '../../helpers/bus'

    import _ from 'lodash'

    const itemsParPage = 25

    export default {
        data: () => {
            return {
                loading: false,
                nombre_documents_visibles: null,
                documents: null,
                documents_selectionnes: [],
                prefixe_documents : '',
                ordre_tri: '+identifiant',
                voir_supprimes: '0',
                pagination: {
                    page: 1,
                    total_pages: 1
                },
                images : {
                    imageLoupe,
                    imageSupprimer,
                    imageRessusciter
                }
            }
        },

        props: ['dossier_courant'],

        created: function() {
            bus.messageBus.$on(bus.cst_type_message.NOUVEAU_DOCUMENT, this.evtNouveauDocument)
            bus.messageBus.$on(bus.cst_type_message.DEPLACEMENT_DOCUMENT, this.evtDeplacementDocument)
        },

        destroyed: function() {
            bus.messageBus.$off(bus.cst_type_message.NOUVEAU_DOCUMENT, this.evtNouveauDocument)
            bus.messageBus.$off(bus.cst_type_message.DEPLACEMENT_DOCUMENT, this.evtDeplacementDocument)
        },

        mounted: function() {
            this.compteDocumentsFils()
            this.chargeDocumentsFils()
        },

        computed: {
            paginationRequise: function() {
                return this.nombre_documents_visibles > itemsParPage
            },
            aucunDocument: function() {
                return !this.loading && this.documents?.length === 0
            },
        },

        methods: {
            compteDocumentsFils: function() {
                this.nombre_documents_visibles = null
                apiDossiers.getNombreDocumentsFils(this.dossier_courant.id)
                    .then(data => {
                        this.nombre_documents_visibles = data.nombre_fils
                    })
                    .catch(error => {
                        console.log(error)
                        // TODO
                    })
            },
            chargeDocumentsFils: function() {
                this.loading = true
                this.documents = null
                this.documents_selectionnes.splice(0, this.documents_selectionnes.length)
                let voir_supprimes = (this.voir_supprimes === '1')
                apiDossiers.getDocumentsFils(this.dossier_courant.id, this.prefixe_documents,
                                            this.pagination.page, itemsParPage,
                                            this.ordre_tri, voir_supprimes)
                    .then(data => {
                        let pagination
                        [this.documents, pagination] = data
                        this.pagination.total_pages = pagination.total_pages
                        this.loading = false
                    })
                    .catch(error => {
                        console.log(error)
                        // TODO
                    })
            },
            changePrefixe: function(prefixe) {
                this.prefixe_documents = prefixe
                this.pagination.page = 1
                this.chargeDocumentsFils()
            },
            changePage: function(nouvellePage) {
                this.pagination.page = nouvellePage
                this.chargeDocumentsFils()
            },

            identifiantFormate: function(document) {
                return noeudHelpers.identifiantFormate(document)
            },
            peutLireDocument: function(document) {
                return document.droit_lire
            },
            iconeTypeDocument: function(document) {
                return documentHelpers.iconeTypeDocument(document)
            },
            iconeImportance: function(document) {
                return documentHelpers.iconeImportance(document)
            },
            dateCreation: function(document) {
                return dateTimeHelpers.dateToDDMMYYYY(
                    dateTimeHelpers.isoToDate(document.cree_le))
            },
            classIdentifiant: function(document) {
                let classes = []
                if(this.estSupprime(document)) {
                    classes.push('est_supprime')
                }
                if(!document.flag_lu) {
                    classes.push('non_lu')
                }
                return classes
            },
            estSupprime: function(document) {
                return document.supprime_le !== null
            },
            peutSupprimer: function(document) {
                return !this.estSupprime(document) && document.droit_supprimer_document
            },
            peutRessusciter: function(document) {
                return this.estSupprime(document) && document.droit_ressusciter
            },
            downloadDocumentUrl: function(document) {
                let derniere_version = noeudHelpers.versionDocumentLePlusRecent(document)
                if(!derniere_version) {
                    return ''
                }

                return apiDocument.getDownloadUrl(noeudHelpers.noeudReelId(document), derniere_version.id)
            },
            evtNouveauDocument: function(nouveau_document) {
                if(nouveau_document.parent_id === this.dossier_courant.id) {
                    this.compteDocumentsFils()
                    this.chargeDocumentsFils()
                    return true
                }
                return false
            },
            evtDeplacementDocument: function({ancien_parent_id, noeud}) {
                if(!this.evtNouveauDocument(noeud))
                {
                    if(ancien_parent_id === this.noeud.id) {
                        this.compteDocumentsFils()
                        this.chargeDocumentsFils()
                    }
                }

            },
            voirDetailsDocument: function(document) {
                this.$store.commit('setDocumentCourant', document)
            },
            clickSupprimer: function(document_a_supprimer) {
                let message = "Voulez-vous supprimer le document suivant : \n\n"
                message += this.identifiantFormate(document_a_supprimer)
                if(confirm(message)) {
                    apiDocument.deleteDocument(document_a_supprimer.id)
                    .then(() => {
                        this.compteDocumentsFils()
                        this.chargeDocumentsFils()
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                }
            },
            clickRessusciter: function(document_a_ressusciter) {
                let message = "Voulez-vous faire renaître le document suivant : \n\n"
                message += this.identifiantFormate(document_a_ressusciter)
                if(confirm(message)) {
                    apiDocument.ressusciterDocument(document_a_ressusciter.id)
                    .then(() => {
                        this.compteDocumentsFils()
                        this.chargeDocumentsFils()
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                }
            },
            clickMarquerLu: function() {
                this._changeFlagLuPourSelection(true)
            },
            clickMarquerNonLu: function() {
                this._changeFlagLuPourSelection(false)
            },
            clickDeplacerVersDossierPrive: async function() {
                if(!this._verifieSelection()) {
                    return
                }

                let dossier_prive_id = this.$store.state.utilisateurCourant.dossier_prive_id
                if(!dossier_prive_id) {
                    // ne devrait pas arriver, mais on va éviter de perdre des
                    // document "dans la nature"...
                    alert("Vous n'avez pas de dossier privé.")
                    return
                }

                await Promise.all(
                    _.map(this.documents_selectionnes, (un_document) => {
                        un_document.parent_id = dossier_prive_id
                        return apiDocument.modifierDocument(un_document)
                    })
                )
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })
                this.compteDocumentsFils()
                this.chargeDocumentsFils()
                this.$store.dispatch('fetchContenuPrive')
            },
            _verifieSelection: function() {
                if(this.documents_selectionnes.length === 0) {
                    alert("Il faut sélectionner des documents pour réaliser cette action.")
                    return false
                }

                for(const un_document of this.documents_selectionnes) {
                    if(noeudHelpers.estSupprime(un_document)) {
                        alert("L'action ne peut être réasliée sur un document supprimé.")
                        return false
                    }
                    if(noeudHelpers.estUnLien(un_document)) {
                        alert("L'action ne peut être réalisée sur un lien.")
                        return false
                    }
                }

                return true
            },
            _changeFlagLuPourSelection: function(nouvelle_valeur) {
                if(!this._verifieSelection()) {
                    return
                }
                Promise.all(
                    _.map(this.documents_selectionnes, (un_document) => {
                        un_document.flag_lu = nouvelle_valeur
                        return apiDocument.modifierDocument(un_document)
                    })
                )
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })
                .finally(() => {
                    this.compteDocumentsFils()
                    this.chargeDocumentsFils()
                })
            },
        },

        watch: {
            dossier_courant: function() {
                this.compteDocumentsFils()
                this.chargeDocumentsFils()
            },
            ordre_tri: function() {
                this.chargeDocumentsFils()
            },
            voir_supprimes: function() {
                this.chargeDocumentsFils()
            }
        },

        components: {
            Pagination
        }
    }
</script>
