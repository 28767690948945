import { French }  from 'flatpickr/dist/l10n/fr'

const cstDateMini = new Date(1990,0, 1)
const cstDateMaxi = new Date(new Date().setUTCHours(0,0,0,0))

function isoToDate(date_iso) {
    // parse YYYY-MM-DD ...
    return new Date(date_iso)
}

function ddmmyyyyToDate(chaine_date) {
    let [chaine_dd, chaine_mm, chaine_yyyy] = chaine_date.split('/')
    let dd = parseInt(chaine_dd)
    let mm = parseInt(chaine_mm)
    let yyyy = parseInt(chaine_yyyy)
    if(!dd || !mm || !yyyy) {
        return null
    }

    return new Date(yyyy, mm -1, dd)
}

function dateToDDMMYYYY(date) {
    let dd = date.getDate().toString().padStart(2, '0')
    let mm = (date.getMonth() + 1).toString().padStart(2, '0')
    let yyyy = date.getFullYear().toString().padStart(4, '0')

    return dd + '/' + mm + '/' + yyyy
}

function dateToDDMMYYYYHHMM(date) {
    let hh = date.getHours().toString().padStart(2, '0')
    let mm = date.getMinutes().toString().padStart(2, '0')

    return dateToDDMMYYYY(date) + ' ' + hh + ':' + mm
}

function defaultFlatPickrConfig() {
    return {
        dateFormat: 'd/m/Y',
        allowInput: true,
        locale: French,
    }
}

export default {
    cstDateMini,
    cstDateMaxi,
    isoToDate,
    ddmmyyyyToDate,
    dateToDDMMYYYY,
    dateToDDMMYYYYHHMM,
    defaultFlatPickrConfig
}