<template>
    <div class="required_vue_root">
        <br/>

    <p>
        <span class="lib_infos_stockage">Libellé du dossier</span>
        <strong>{{ identifiantFormate }}</strong>
    </p>

    <hr/>

    <div class="artificial_container"
        v-if="!etatEditionStockage">
        <p>
            <span class="lib_infos_stockage">Stockage actuel</span>
            {{ stockageActuel }}
            &nbsp;
            <a href="#" @click.prevent="clickEditer"><img :src="images.imageEditer"></a>
        </p>

        <p>
            <span class="lib_infos_stockage">Source</span>
            {{ sourceStockage }}
        </p>
    </div>

    <div v-else>
        <p>
            <span class="lib_infos_stockage">Nouveau stockage</span>
            <select class="type_texte" v-model="select_stockage">
                <option value="-" v-if="stockage_id">supprimer le paramétrage de stockage</option>
                <option v-for="stockage of stockages" :key="stockage.id" :value="stockage.id">{{ stockage.identifiant }}</option>
            </select>
        </p>

        <p>
            <span class="lib_infos_stockage">&nbsp;</span>
            <a href="#" @click.prevent="clickEnregistrer"><strong>Changer le stockage</strong></a>
            &nbsp;&nbsp;
            <a href="#" @click.prevent="clickAnnuler"><strong>Annuler</strong></a>
        </p>
    </div>

    <hr/>

    <p>
        <em>
            Le stockage paramétre concerne les nouveaux documents. Des documents peuvent exister dans ce dossier (et les sous-dossier héritant du même paramère) ayant un stockage différent.
        </em>
    </p>
    </div>
</template>

<script>
    import apiStockages from '../../../api/stockages'
    import apiDossiersStockage from '../../../api/dossiers_stockage'

    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    import imageEditer from '../../../../assets/images/icones/pencil.png'

    import _ from 'lodash'

    const etatsOperations = {
        VISUALISATION: 0,
        EDITION_STOCKAGE: 1
    }

    export default {
        data: () => {
            return {
                etat_courant: etatsOperations.VISUALISATION,
                stockages: [],
                stockage_id: null,
                stockage_effectif_id: null,
                select_stockage: null,
                images : {
                    imageEditer,
                }
            }
        },

        created: async function() {
            await this._chargeStockages()
            await this._chargeStockagePourDossier()
        },

        computed: {
            dossier_courant: function() {
                return this.$store.state.dossier_courant
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.dossier_courant)
            },
            stockageActuel: function() {
                if(this.stockage_effectif_id) {
                    let stockage = this._stockageAvecId(this.stockage_effectif_id)
                    return stockage.identifiant
                } else {
                    return "en base de données (obsolète)"
                }
            },
            sourceStockage: function() {
                if(this.stockage_id) {
                    return "stockage explicitement paramétré ici sur ce dossier"
                } else if(noeudHelpers.estRacine(this.dossier_courant)) {
                    return "stockage par défaut pour la racine"
                } else {
                    return "stockage hérité d'un dossier parent"
                }
            },
            etatEditionStockage: function() {
                return this.etat_courant === etatsOperations.EDITION_STOCKAGE
            },
        },

        methods: {
            clickEditer: function() {
                 this.etat_courant = etatsOperations.EDITION_STOCKAGE
                 this.select_stockage = this.stockage_id
            },
            clickEnregistrer: function() {
                if(this.select_stockage === "-") {
                    // supprimer le paramétrage
                    apiDossiersStockage.deleteStockage(this.dossier_courant.id)
                    .then(() => {
                        this.etat_courant = etatsOperations.VISUALISATION
                        this._chargeStockagePourDossier()
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                } else {
                    // définir le paramétrage de stockage
                    let nouveau_stockage_id = parseInt(this.select_stockage)
                    apiDossiersStockage.postStockage(this.dossier_courant.id, nouveau_stockage_id)
                    .then(() => {
                        this.etat_courant = etatsOperations.VISUALISATION
                        this._chargeStockagePourDossier()
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                }
            },
            clickAnnuler: function() {
                this.etat_courant = etatsOperations.VISUALISATION
            },            
            _chargeStockages: async function() {
                let data = await apiStockages.getStockages()
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                this.stockages = data || []
                this.stockages = _.filter(this.stockages, function(s) {
                    return s.flag_proposer === true
                })
            },
            _chargeStockagePourDossier: async function() {
                let data = await apiDossiersStockage.getStockage(this.dossier_courant.id)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                this.stockage_id = data?.stockage_id
                this.stockage_effectif_id = data?.stockage_effectif_id
            },
            _stockageAvecId: function(id) {
                return _.find(this.stockages, function(s) {
                    return s.id === id
                })
            }
        },

        watch: {
            dossier_courant: function() {
                this.etat_courant = etatsOperations.VISUALISATION
                this._chargeStockagePourDossier()
            }
        }

    }
</script>
