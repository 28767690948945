import dateTimeHelpers from './date_time'
import _ from 'lodash'

const cst_type_metadonnees = {
    NUMERIQUE:  0,
    CHAINE:     1,
    DATE:       2,
    ENUMERE:    3,
    BOOLEEN:    4
}

function flatMetadonnees(noeud, familles_et_parametrages) {
    let metas = []
    for(let f of noeud.metadonnees_par_familles) {
        let famille = _.find(familles_et_parametrages, (fm) => { return fm.id === f.famille_id})
        if(famille) {
            for(let m of f.metadonnees) {
                let param = _.find(famille.parametrages_metadonnees, (pm) => { return pm.id === m.param_id})
                if(param) {
                    let meta = {
                        param_id: m.param_id,
                        famille: famille.libelle,
                        metadonnee: param.libelle,
                        existe_valeur: (!!m.valeur),
                        type_metadonnee: param.type_donnee,
                        valeurs_possibles: param.valeurs_possibles,
                        valeur: (m.valeur ? m.valeur : m.defaut),
                    }
                    metas.push(meta)
                }
            }
        }
    }
    return metas
}

function majMetadonnesNoeud(noeud, flat_metadonnees) {
    for(let f of noeud.metadonnees_par_familles) {
        for(let m of f.metadonnees) {
            let meta_saisie = _.find(flat_metadonnees, (flat_meta) => { return m.param_id === flat_meta.param_id })
            if(meta_saisie) {
                if(meta_saisie.existe_valeur) {
                    m.valeur = meta_saisie.valeur
                } else {
                    m.valeur = null
                }
            } else {
                console.log(`Aucune corresponsance de metadonnée pour ${m}`)
            }
        }
    }
}

export default {
    flatMetadonnees,
    majMetadonnesNoeud,
    cst_type_metadonnees
}