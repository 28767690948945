import axios from 'axios'

const timeout = 10000 // ms
const baseURL = 'api/v3/' // laisser le '/' à la fin !

function setInterceptor() {
    let interceptor = axiosInstance.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if(error.response) {
                console.log("Réponse en erreur")
                console.log(error.response)
            } else if(error.request) {
                console.log("Aucune réponse")
            } else {
                console.log('Erreur', error.message);
            }

            if(error.response && error.response.status === 401) {
                window.location.href = '/identification'
            }

            return Promise.reject(error)
        }
    )
}

// Note : axios handle application/json by default
const axiosInstance = axios.create({
    timeout,
    baseURL
})

setInterceptor()

export default axiosInstance
