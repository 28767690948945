<template>
    <div class="required_vue_root">
        <ErreursSaisie v-if="erreurs" v-bind:liste_erreurs="erreurs" />

        <p>
            <span class="lib_ajout_document">Libellé </span>
            <input class="type_texte" maxlength="250" size="40" type="text" placeholder="nom du dossier"
                   v-model="identifiant" />
        </p>

        <p>
            <span class="lib_ajout_document">Description </span>
            <textarea cols="40" rows="5" placeholder="description facultative"
                      v-model="description"/>
        </p>

        <p>
            &nbsp;
            <a href="#" @click.prevent="clickCreerDossier"><strong>Créer le dossier</strong></a>&nbsp;
            <a href="#" @click.prevent="clickAnnuler">Annuler</a>
        </p>
        <hr id="sous_zone_identifiant"/>
    </div>
</template>

<script>
    import ErreursSaisie from '../utilitaires/erreurs_saisie'
    import apiDossiers from '../../../api/dossiers'
    import erreursHelpers from '../../helpers/erreurs'

    export default {
        data: () => {
            return {
                identifiant: null,
                description: null,
                erreurs: null
            }
        },

        props: ['dossier_parent'],

        methods: {
            clickAnnuler: function() {
                this.$emit('cloture', null)
            },
            clickCreerDossier: function() {
                this.erreurs = null
                apiDossiers.creerDossier(this.dossier_parent.id, this.identifiant, this.description)
                .then((response) => {
                    let nouveau_dossier = response
                    this.$emit('cloture', nouveau_dossier)
                })
                .catch((error) => {
                    let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                    if(data) {
                        this.erreurs = data.error
                        console.log(this.erreurs)
                    }
                })
            }
        },
        components: {
            ErreursSaisie,
        }
    }
</script>
