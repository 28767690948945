import http from './http'

function getStockages() {
    let url = `admin/stockages`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

export default {
    getStockages,
}
