import http from './http'

async function getAclsDossier(noeud_id) {
    return await _getAclsNoeuds(noeud_id, false)
}

async function getAclsDocument(noeud_id) {
    return await _getAclsNoeuds(noeud_id, true)
}

async function postAclDossier(noeud_id, acl, flagUtilisateur) {
    return await _postAclNoeud(noeud_id, acl, false, flagUtilisateur)
}

async function putAclDossier(noeud_id, acl, flagUtilisateur) {
    return await _putAclNoeud(noeud_id, acl, false, flagUtilisateur)
}

async function deleteAclDossier(noeud_id, target_id, flagUtilisateur) {
    return await _deleteAclNoeud(noeud_id, target_id, false, flagUtilisateur)
}

async function postAclDocument(noeud_id, acl, flagUtilisateur) {
    return await _postAclNoeud(noeud_id, acl, true, flagUtilisateur)
}

async function putAclDocument(noeud_id, acl, flagUtilisateur) {
    return await _putAclNoeud(noeud_id, acl, true, flagUtilisateur)
}

async function deleteAclDocument(noeud_id, target_id, flagUtilisateur) {
    return await _deleteAclNoeud(noeud_id, target_id, true, flagUtilisateur)
}

async function appliquerModeleDroitsSurDossier(noeud_id, modele_id, reset_droits) {
    return _appliquerModeleDroitsSurNoeud(noeud_id, modele_id, reset_droits, false)
}

async function appliquerModeleDroitsSurDocument(noeud_id, modele_id, reset_droits) {
    _appliquerModeleDroitsSurNoeud(noeud_id, modele_id, reset_droits, true)
}

async function _getAclsNoeuds(noeud_id, flagDocument) {
    let acl_groupes = await _getAclsNoeudTarget(noeud_id, flagDocument, false)
    let acl_utilisateurs = await _getAclsNoeudTarget(noeud_id, flagDocument, true)
    let acl_origine = await _getAclsOrigine(noeud_id, flagDocument)
    return {
        groupes: acl_groupes,
        utilisateurs: acl_utilisateurs,
        noeud_acls_id: acl_origine.noeud_acls_id
    }
}

function _getAclsNoeudTarget(noeud_id, flagDocument, flagUtilisateur) {
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/${_acltarget_subpath(flagUtilisateur)}`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function _getAclsOrigine(noeud_id, flagDocument, flagUtilisateur) {
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/origine`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function _postAclNoeud(noeud_id, acl, flagDocument, flagUtilisateur) {
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/${_acltarget_subpath(flagUtilisateur)}`

    return http.post(url, acl)
        .then(function(response) {
            return response.data
        })
}

function _putAclNoeud(noeud_id, acl, flagDocument, flagUtilisateur) {
    let target_id = _getAclTargetId(acl,flagUtilisateur)
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/${_acltarget_subpath(flagUtilisateur, target_id)}`

    return http.put(url, acl)
        .then(function(response) {
            return response.data
        })
}

function _deleteAclNoeud(noeud_id, target_id, flagDocument, flagUtilisateur) {
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/${_acltarget_subpath(flagUtilisateur, target_id)}`

    return http.delete(url)
        .then(function(response) {
            return response.data
        })
}

async function _appliquerModeleDroitsSurNoeud(noeud_id, modele_id, reset_droits, flagDocument) {
    let url = `admin/${_noeud_subpath(noeud_id, flagDocument)}/acls/modele/${modele_id}`

    return http.post(url, {reset_avant_application: reset_droits})
        .then(function(response) {
            return response.data
        })
}

function _noeud_subpath(noeud_id, flagDocument) {
    let noeud_type_path = flagDocument ? 'documents' : 'dossiers'
    return `${noeud_type_path}/${noeud_id}`
}

function _acltarget_subpath(flagUtilisateur, target_id = null) {
    let target_type_path = flagUtilisateur ? 'utilisateurs' : 'groupes'
     let subpath = `${target_type_path}`
     if(target_id) {
        subpath += `/${target_id}`
     }
     return subpath
}

function _getAclTargetId(acl, flagUtilisateur) {
    return flagUtilisateur ? acl.utilisateur_id : acl.groupe_id
}

export default {
    getAclsDossier,
    postAclDossier,
    putAclDossier,
    deleteAclDossier,
    getAclsDocument,
    postAclDocument,
    putAclDocument,
    deleteAclDocument,
    appliquerModeleDroitsSurDossier,
    appliquerModeleDroitsSurDocument
}
