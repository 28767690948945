<template>
  <div id="navigation">
      <HorsArborescence/>
      <ContenuGed/>
  </div>
</template>

<script>
    import HorsArborescence from './hors_arborescence/hors_arborescence.vue'
    import ContenuGed from './contenu_ged.vue'
    
    export default {
        components: {
            HorsArborescence,
            ContenuGed,
        }
    }
</script>

