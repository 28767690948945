<template>
    <span>
        <span v-if="estRacine">(aucun ancêtre)</span>
        <template v-else>
            <span v-if="!ancetres">...</span>
            <span v-else
                  v-for="(n, i) of ancetres" :key="n.id">
                <a href="#" @click.prevent="clickLien(n)">{{ n.identifiant }}</a>
                <span v-if="i < (ancetres.length - 1)"> » </span>
            </span>
        </template>
    </span>
</template>

<script>
    import apiDossiers from '../../../api/dossiers'
    import apiDocuments from '../../../api/documents'

    import noeudHelpers from '../../helpers/noeud'

    export default {
        data: () => {
            return {
                ancetres: null
            }
        },

        props: ['noeud'],

        created: async function() {
            await this._initDonnees()
        },

        computed: {
            estRacine: function() {
                return noeudHelpers.estRacine(this.noeud)
            },
        },
        methods: {
            clickLien: function(noeud_cible) {
                this.$emit('clickNoeud', noeud_cible)
            },
            _initDonnees: async function() {
                let api = noeudHelpers.estUnDossier(this.noeud) ?
                            apiDossiers : 
                            apiDocuments
                let chemin = await api.getAncetres(this.noeud.id)
                                .catch(error => {
                                    console.log(error)
                                    return
                                })
                this.ancetres = chemin.splice(0, chemin.length - 1)
            }
        }



    }
</script>
