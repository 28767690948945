import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Navigation from '../navigation/composants/navigation.vue'
import store from '../navigation/store/store.js'

Vue.use(TurbolinksAdapter)

let init = async() => {
    let el = document.querySelector('#navigation');
    if(!el) {
      return
    }

    const params = JSON.parse(el.getAttribute('data'))
    if(!params) {
      console.log("Il manque l'attribut data à #navigation")
      return
    }

    await store.dispatch('initStore', params['utilisateurCourantId'])
  
    new Vue({
      store,
      render: h => h(Navigation)
    }).$mount('#navigation')
}

document.addEventListener('turbolinks:load', async () => {
  await init()
})
