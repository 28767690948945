<template>
    <div class="required_root">
        <p id="zone_lien_recherche">
            &nbsp;&nbsp;
            <a href="#" class="lien_recherche" @click.prevent="ouvreRecherche">
                <img v-bind:src="images.imageRecherche" />
                &nbsp;&nbsp;Rechercher un document TODO 
            </a>
        </p>
        <keep-alive>
            <Recherche v-if="recherche_ouverte"
                    @finRecherche="finRecherche" />
        </keep-alive>
    </div>
</template>

<script>
    import Recherche from './recherche/recherche.vue'

    import imageRecherche from "images/icone_chercher.png";
    
    export default {
        data: () => {
            return {
                recherche_ouverte: false,
                images: {
                    imageRecherche
                }
            }
        },

        methods: {
            ouvreRecherche: function(){
                this.recherche_ouverte = true
            },
            finRecherche: function(){
                this.recherche_ouverte = false
            }
        },

        components: {
            Recherche,
        }
    }
</script>



