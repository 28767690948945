<template>
    <div class="required_vue_root">
        <br/>
        <p>
            <a href="#" @click.prevent="remonterDossierParent()">
                remonter au dossier parent.
            </a>
        </p>
        <hr/>

        <div v-if="!etatEditionDocument" class="artificial_container" >
            <p>
                <span class="lib_infos_document">Libellé</span>
                <strong>{{ identifiantFormate }}</strong>
            </p>
            <p>
                <span class="lib_infos_document">&nbsp;</span>
                <a class="icone_avec_infobulle" href="#"
                    v-if="afficherEditerLibelle"
                    v-on:click.prevent="editerInformations" >
                    <img v-bind:src="images.imageEditer" />
                    <span class="bulle_a_droite">Modifier le libellé</span>
                </a>
                <a class="icone_avec_infobulle" href="#"
                    v-if="afficherCouper"
                    v-on:click.prevent="couper" >
                    <img v-bind:src="images.imageCouper" />
                    <span class="bulle_a_droite">Déplacer vers le dossier privé</span>
                </a>
                <a class="icone_avec_infobulle" href="#"
                    v-if="afficherSupprimer"
                    v-on:click.prevent="supprimer" >
                    <img v-bind:src="images.imageSupprimer" />
                    <span class="bulle_a_droite">Supprimer</span>
                </a>
                <a class="icone_avec_infobulle" href="#"
                    v-if="afficherAjouterSelection"
                    v-on:click.prevent="ajouterSelection" >
                    <img v-bind:src="images.imageSelectionner" />
                    <span class="bulle_a_droite">Ajouter à la sélection</span>
                </a>
            </p>

            <p>
                <span class="lib_infos_document">&nbsp;</span>
                Il existe {{ nombreVersions }} version(s) du document.
            </p>

            <p>
                <span class="lib_infos_document">&nbsp;</span>
                <a :href="downloadDocumentUrl" target="_blank">Ouvrir le document.</a>
            </p>

            <p>
                <span class="lib_infos_document">Origine/Exp.</span>
                {{ documentAJour.origine}}&nbsp;
            </p>

            <p>
                <span class="lib_infos_document">Important/Urgent</span>
                <img alt="Pas_important" v-bind:src="iconeImportance">
            </p>

            <p>
                <span class="lib_infos_document">Description</span>
                <textarea class="visu_description_document" cols=40 readonly="readonly" rows="10" v-model="this.documentAJour.description"/>
            </p>

            <p>
                <span class="lib_infos_document">Mots clés</span>
                <textarea class="visu_description_document" cols=40 readonly="readonly" rows="3" v-model="motsCles"/>
            </p>

            <p>
                <span class="lib_infos_document">Date d'origine</span>
                {{ horodatageOrigine }}&nbsp;
            </p>

            <p>
                <span class="lib_infos_document">Créé le</span>
                {{ creeLe }}&nbsp;
            </p>

            <p>
                <span class="lib_infos_document">Créé par</span>
                {{ documentAJour.cree_par.identifiant }}&nbsp;
            </p>

            <p>
                <span class="lib_infos_document">Traité par</span>
                {{ documentAJour.traite_par.identifiant }}&nbsp;
            </p>

        </div>

        <DetailsModifierDocument
            v-if="etatEditionDocument"
            v-bind:document_courant="documentAJour"
            v-on:cloture="evtFinEditionDocument($event)" />

        <hr/>

        <DetailsContenuVersion
            v-for="version_document of documentAJour.versions_documents"
            :key="version_document.id"
            :version_courante="version_document"
            :document_id="documentAJour.id" />

        <p>Ajouter une nouvelle version du document.</p>
        <p>
            Nouvelle version :
            <input type="file" @change="selectionFichier($event)">
        </p>
        <p><a href="#" @click.prevent="clickAjouterVersion()">Ajouter la nouvelle version</a></p>

    </div>
</template>

<script>
    import DetailsModifierDocument from './details_modifier_document'
    import DetailsContenuVersion from './details_contenu_version'

    import apiDossiers from '../../../api/dossiers'
    import apiDocuments from '../../../api/documents'
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'
    import erreursHelpers from '../../helpers/erreurs'
    import dateTimeHelpers from '../../helpers/date_time'

    import imageEditer from '../../../../assets/images/icones/pencil.png'
    import imageCouper from '../../../../assets/images/icones/cut.png'
    import imageSupprimer from '../../../../assets/images/icones/bin_empty.png'
    import imageSelectionner from '../../../../assets/images/icones/link_go.png'

    const etatsOperations = {
        VISUALISATION: 0,
        EDITION_DOCUMENT: 1
    }

    export default {
        data: () => {
            return {
                etat_courant: etatsOperations.VISUALISATION,
                document_recharge: null,
                dossier_parent: null,
                motsCles: '',
                fichier: null,
                envoi_en_cours: false,
                images : {
                    imageEditer,
                    imageCouper,
                    imageSupprimer,
                    imageSelectionner
                }
            }
        },


        props: ['document_courant'],

        created: function() {
            this.initState()
        },

        computed: {
            documentAJour: function() {
                return this.document_recharge ? this.document_recharge : this.document_courant
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.documentAJour)
            },
            nombreVersions: function() {
                if(this.documentAJour.versions_documents)
                {
                    return this.documentAJour.versions_documents.length
                } else {
                    return 0
                }
            },
            estUnLien: function() {
                return noeudHelpers.estUnLien(this.documentAJour)
            },
            afficherEditerLibelle: function() {
                return this.documentAJour?.droit_ecrire
            },
            afficherCouper: function() {
                return this.documentAJour?.droit_supprimer_document &&
                       this.documentAJour?.id !== this.$store.state.racine?.id &&
                       ! this.estUnLien
            },
            afficherSupprimer: function() {
                return this.documentAJour?.droit_supprimer_document &&
                       this.documentAJour?.id !== this.$store.state.racine?.id
            },
            afficherAjouterSelection: function () {
                return this.documentAJour?.id !== this.$store.state.racine?.id &&
                       ! this.estUnLien
            },
            downloadDocumentUrl: function() {
                let derniere_version = noeudHelpers.versionDocumentLePlusRecent(this.documentAJour)
                if(!derniere_version) {
                    return ''
                }

                return apiDocuments.getDownloadUrl(noeudHelpers.noeudReelId(this.documentAJour), derniere_version.id)
            },
            horodatageOrigine: function() {
                return dateTimeHelpers.dateToDDMMYYYYHHMM(dateTimeHelpers.isoToDate(this.documentAJour.horodatage_origine))
            },
            iconeImportance: function() {
                return documentHelpers.iconeImportance(this.documentAJour)
            },
            creeLe: function() {
                return dateTimeHelpers.dateToDDMMYYYYHHMM(dateTimeHelpers.isoToDate(this.documentAJour.cree_le))
            },
            etatEditionDocument: function() {
                return this.etat_courant === etatsOperations.EDITION_DOCUMENT
            }
        },

        methods: {
            initState: function() {
                this.motsCles = noeudHelpers.motsClesArrayToString(this.documentAJour.mots_cles)
                this.chargeDocumentCourant()
                this.chargeNoeudParent()
                this.chargeNombreLiens()
            },
            chargeDocumentCourant: function() {
                apiDocuments.lireDocument(this.documentAJour.id)
                    .then((data) =>  {
                        this.document_recharge = data
                    })
                    .catch((error) => {
                        this.document_recharge = null
                        erreursHelpers.alertMessageErreur(error)
                    })
            },
            chargeNoeudParent: function() {
                apiDossiers.lireDossier(this.documentAJour.parent_id)
                    .then((data) =>  {
                        this.dossier_parent = data
                    })
                    .catch((error) => {
                        this.dossier_parent = null
                        erreursHelpers.alertMessageErreur(error)
                    })
            },
            chargeNombreLiens: function() {
                if(noeudHelpers.estUnLien(this.documentAJour)) {
                    this.nombre_liens = 0
                    return
                }
                apiDocuments.getNombreLiens(this.documentAJour.id)
                    .then((data) => {
                        this.nombre_liens = data
                    })
                    .catch((error) => {
                        this.nombre_liens = 0
                        erreursHelpers.alertMessageErreur(error)
                    })
            },
            remonterDossierParent: function() {
                this.$store.commit('setDocumentCourant', null)
            },
            editerInformations: function() {
                this.etat_courant = etatsOperations.EDITION_DOCUMENT
            },
            couper: function() {
                alert('todo')
            },
            supprimer: function() {
                if(confirm("Voulez-vous vraiment supprimer ce document ?")) {
                    apiDocuments.deleteDocument(this.documentAJour.id)
                    .then( (data) => {
                        // aucun donnée, mais succes
                        this.remonterDossierParent()
                    })
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })
                }
            },
            ajouterSelection: function() {
                this.$store.commit('addSelectionDocument', this.documentAJour)
            },
            selectionFichier: function() {
                this.fichier = event?.target?.files[0]
                this.identifiant = this.fichier.name
            },
            clickAjouterVersion: function() {
                if(this.envoi_en_cours) {
                    return
                }

                if(!this.fichier) {
                    alert('Il faut sélectionner un fichier.')
                    return
                }

                this.envoi_en_cours = true
                apiDocuments.postVersionDocument(this.documentAJour.id, this.fichier)
                .then(() => {
                    this.envoi_en_cours = false
                    this.initState()
                })
                .catch((error) => {
                    this.envoi_en_cours = false
                    erreursHelpers.alertMessageErreur(error)
                })
            },
            evtFinEditionDocument: function(document_modifie) {
                this.etat_courant = etatsOperations.VISUALISATION
                if(document_modifie !== null) {
                    this.initState()
                }
            },
        },

        watch: {
            document_courant: function() {
                this.initState()
            },
        },

        components: {
            DetailsContenuVersion,
            DetailsModifierDocument
        }
    }
</script>
