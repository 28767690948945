<template>
    <div class="required_vue_root">
        <DossierGestion
            v-bind:dossier_courant="dossier_courant" />

        <DossierListeDocuments
            v-bind:dossier_courant="dossier_courant" />
    </div>
</template>

<script>
    import apiDossiers from '../../../api/dossiers'

    import DossierGestion from './dossier_gestion'
    import DossierListeDocuments from './dossier_liste_documents'

    export default {
        data: () => {
            return {}
        },

        props: ['dossier_courant'],

        components: {
            DossierGestion,
            DossierListeDocuments
        }

    }
</script>
