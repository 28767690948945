<template>
    <li v-bind:class="{noeud_selectionne: estDosierCourant,
                       noeud_ouvert: ouvert,
                       noeud_ferme: !ouvert}">
        <a href="#" v-on:click.prevent="toggleOuvertFerme()">
            <img v-bind:src="imagePuce" />
        </a>
        <img v-bind:src="iconeDossier" />
        <a href="#" v-on:click.prevent="selectionneNoeudCourant()" class=" lien_avec_infobulle_petit">
            {{ identifiantFormate }}
            <span v-if="noeud.description.length > 0" class="bulle_a_gauche">{{ descriptionFormatee }}</span>
        </a>

        <div class="contenu_dossier_ouvert" v-if="ouvert">
            <Pagination v-if="ouvert && paginationRequise"
                        v-bind:prefixe_initial="prefixe_enfants"
                        v-bind:page="pagination.page"
                        v-bind:total_pages="pagination.total_pages"
                        v-on:change_prefixe="changePrefixe($event)"
                        v-on:change_page="changePage($event)"
            />

            <ul class="noeud_ouvert" v-if="ouvert">
                <li v-if="loading" class="contenu_noeud_vide">Chargement...</li>
                <li v-if="aucunSousDossier" class="contenu_noeud_vide">Aucun sous-dossier</li>
                <NoeudArborescence
                    v-for="dossier in enfants"
                    :key="dossier.id"
                    :noeud="dossier"
                    :force_chemin="force_chemin" />
            </ul>
        </div>
    </li>
</template>

<script>
    import Pagination from '../utilitaires/pagination'

    import imageNoeudOuvert from '../../../../assets/images/puce_pointe_bas.png'
    import imageNoeudFerme from '../../../../assets/images/puce_pointe_droite.png'
    import imgageDossier from '../../../../assets/images/icones/documents/folder.png'
    import imageLien from '../../../../assets/images/icones/link.png'
    import apiDossiers from '../../../api/dossiers.js'
    import bus from '../../helpers/bus'
    import noeudHelpers from '../../helpers/noeud'

    import _ from 'lodash'

    const itemsParPage = 25

    export default {
        name: 'NoeudArborescence',
        data: () => {
            return {
                loading: false,
                ouvert: false,
                nombre_dossiers_fils_visibles: null,
                enfants: null,
                prefixe_enfants : null,
                pagination: {
                    page: 1,
                    total_pages: 1
                },
                images : {
                    imageNoeudOuvert,
                    imageNoeudFerme,
                    imgageDossier,
                    imageLien
                }
            }
        },

        props: ['noeud', 'force_chemin'],

        created: function() {
            bus.messageBus.$on(bus.cst_type_message.NOUVEAU_DOSSIER, this.evtNouveauDossier)
            bus.messageBus.$on(bus.cst_type_message.MODIFICATION_DOSSIER, this.evtModificationDossier)
            bus.messageBus.$on(bus.cst_type_message.SUPPRESSION_DOSSIER, this.evtSuppressionDossier)
            bus.messageBus.$on(bus.cst_type_message.DEPLACEMENT_DOSSIER, this.evtDeplacementDossier)
            this.forceCheminSiBesoin()
        },

        destroyed: function() {
            bus.messageBus.$off(bus.cst_type_message.NOUVEAU_DOSSIER, this.evtNouveauDossier)
            bus.messageBus.$off(bus.cst_type_message.MODIFICATION_DOSSIER, this.evtModificationDossier)
            bus.messageBus.$off(bus.cst_type_message.SUPPRESSION_DOSSIER, this.evtSuppressionDossier)
            bus.messageBus.$off(bus.cst_type_message.DEPLACEMENT_DOSSIER, this.evtDeplacementDossier)
        },

        computed: {
            estDosierCourant: function () {
                return this.noeud?.id === this.$store.state.dossier_courant?.id;
            },

            aucunSousDossier: function() {
                return !this.loading && this.enfants?.length === 0
            },

            imagePuce: function() {
                if(this.ouvert) {
                    return this.images.imageNoeudOuvert
                } else {
                    return this.images.imageNoeudFerme
                }
            },

            paginationRequise: function() {
                return this.nombre_dossiers_fils_visibles > itemsParPage
            },

            estUnLien: function() {
                return this.noeud?.noeud_reel_id > 0
            },

            iconeDossier: function() {
                if(this.estUnLien) {
                    return this.images.imageLien
                }

                return this.images.imgageDossier
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.noeud)
            },
            descriptionFormatee: function() {
                return noeudHelpers.descriptionFormatee(this.noeud)
            }
        },

        methods: {
            fermeDossier: function() {
                this.ouvert = false
                this.enfants = null
            },

            ouvreDossier: function() {
                this.ouvert = true
                this.pagination.page = 1
                this.prefixe_enfants = null
                // A l'ouverture, le décompte du totale des fils et le chargement
                // du détails sont concurrents pour améliorer la réactivité.
                // (attention à coder en conséquence)
                this.compteDossiersFils()
                this.chargeDossiersFils()
                this.$store.commit('setDossierCourant', this.noeud)
            },

            toggleOuvertFerme: function() {
                if(this.ouvert) {
                    this.fermeDossier()
                    return
                }

                this.ouvreDossier()
            },

            selectionneNoeudCourant: function() {
                if(this.ouvert) {
                    this.$store.commit('setDossierCourant', this.noeud)
                } else {
                    this.ouvreDossier()
                }
            },

            compteDossiersFils: function() {
                this.nombre_dossiers_fils_visibles = null
                return new Promise((successCallback, failureCallback) => {
                    apiDossiers.getNombreDossiersFils(this.noeud.id)
                    .then(data => {
                        this.nombre_dossiers_fils_visibles = data.nombre_fils
                        successCallback()
                    })
                    .catch(error => {
                        console.log(error)
                        failureCallback()
                        // TODO
                    })
                })
            },

            chargeDossiersFils: function() {
                this.loading = true
                this.enfants = null
                apiDossiers.getDossiersFils(this.noeud.id, this.prefixe_enfants, this.pagination.page, itemsParPage)
                    .then(data => {
                        let pagination
                        [this.enfants, pagination] = data
                        this.pagination.total_pages = pagination.total_pages
                        this.loading = false
                    })
                    .catch(error => {
                        console.log(error)
                        // TODO
                    })
            },

            changePrefixe: function(prefixe) {
                this.prefixe_enfants = prefixe
                this.pagination.page = 1
                this.chargeDossiersFils()
            },

            changePage: function(nouvellePage) {
                this.pagination.page = nouvellePage
                this.chargeDossiersFils()
            },

            evtNouveauDossier: function(nouveau_noeud) {
                if(this.enfants && nouveau_noeud.parent_id === this.noeud.id) {
                    this.enfants.push(nouveau_noeud)
                }
            },
            evtModificationDossier: function(noeud_modifie) {
                if(this.enfants && noeud_modifie.parent_id === this.noeud.id) {
                    for(let i=0; i < this.enfants.length; i++) {
                        if(this.enfants[i]?.id === noeud_modifie.id) {
                            this.enfants.splice(i, 1, noeud_modifie)
                            break
                        }
                    }
                }
            },
            evtSuppressionDossier: function(noeud_supprime) {
                this._retirerDossier(noeud_supprime, noeud_supprime.parent_id)
            },
            _retirerDossier: function(noeud, parent_id) {
                if(this.enfants && parent_id === this.noeud.id) {
                    for(let i=0; i < this.enfants.length; i++) {
                        if(this.enfants[i]?.id === noeud.id) {
                            this.enfants.splice(i, 1)
                            break
                        }
                    }
                }
            },
            evtDeplacementDossier: function({ancien_parent_id, noeud}) {
                // le dossier peut 'entrer' ou 'sortir'
                this.evtNouveauDossier(noeud)
                this._retirerDossier(noeud, ancien_parent_id)
            },
            forceCheminSiBesoin: function() {
                if(!this.force_chemin) {
                    return
                }

                // On est au noeud courant, même si un chemin est demandé
                // on est arrivé.
                if(this.noeud.id === this.$store.state.dossier_courant.id) {
                    // Le chemin ayant été construit, il est supprimé pour
                    // permettre un comportement normal de l'interface.
                    this.$store.commit('setAncetresDossierCourant', null)
                    return
                }

                // Est-ce qu'il y a besoin de forcer un chemin ?
                let ancetres_dossier_courant = this.$store.state.ancetres_dossier_courant
                if(!ancetres_dossier_courant || ancetres_dossier_courant.length === 0) {
                    return
                }

                let fils_a_presenter = null
                let prendre_prochain = false
                for(let ancetre of ancetres_dossier_courant) {
                    if(ancetre.id === this.noeud.id) {
                        prendre_prochain = true
                        continue
                    }
                    if(prendre_prochain) {
                        fils_a_presenter = ancetre
                        break
                    }
                }

                if(!fils_a_presenter) {
                    // Possible lorsqu'il y a plusieurs sous-dossiers commençant
                    // par la même chaine que l'identifiant recherché.
                    // ex : on veut afficher 'foo', mais il y a aussi 'foo-bar'
                    //      dans le résultat du filtrage par préfixe.
                    return
                }

                this.ouvert = true
                this.compteDossiersFils()
                    .then(() => {
                        if(this.paginationRequise){
                            this.changePrefixe(fils_a_presenter.identifiant)
                        } else {
                            this.changePrefixe(null)
                        }
                    })
            }
        },

        watch: {
            force_chemin: function() {
                this.forceCheminSiBesoin()
            }
        },

        components: {
            Pagination
        }
    }
</script>
