<template>
    <div class="errorExplanation_small">
        <h2 v-if="liste_erreurs_normalisee.length === 1">Il y a une erreur dans la saisie</h2>
        <h2 v-else>Il y a des erreurs dans la saisie</h2>

        <ul>
            <li v-for="erreur of liste_erreurs_normalisee" :key="erreur">{{ erreur }}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        data: () => {
            return {}
        },

        props: ['liste_erreurs'],

        computed: {
            liste_erreurs_normalisee: function() {
                if(typeof this.liste_erreurs === 'string' || this.liste_erreurs instanceof String) {
                    return [this.liste_erreurs]
                }

                return this.liste_erreurs
            }
        }
    }
</script>
