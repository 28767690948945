<template>
    <div class="required_vue_root">
        <ErreursSaisie v-if="erreurs" v-bind:liste_erreurs="erreurs" />

        <p>
            <span class="lib_ajout_document">Nouveau document</span>
            <input size="16" type="file" @change="selectionFichier($event)">
        </p>

        <p>
            <span class="lib_ajout_document">Identifiants proposés</span>
            <select class="type_texte" v-model="identifiant_selectionne" v-on:change="selectionIdentifiant">
                <optgroup v-for="categorie of liste_identifiants" :key="categorie.id" v-bind:label="categorie.libelle">
                    <option v-for="identifiant of categorie.identifiants" :key="identifiant">{{ identifiant }}</option>
                </optgroup>
            </select>
        </p>

        <p>
            <span class="lib_ajout_document">Identifiant</span>
            <input class="type_texte" maxlength="64" size="40" type="text" placeholder="nom du document"
                   v-model="identifiant" />
        </p>

        <p>
            <span class="lib_ajout_document">Origines proposées</span>
            <select class="type_texte" v-model="origine_selectionnee" v-on:change="selectionOrigine">
                <optgroup v-for="categorie of liste_origines" :key="categorie.id" v-bind:label="categorie.libelle">
                    <option v-for="origine of categorie.origines" :key="origine">{{ origine }}</option>
                </optgroup>
            </select>
        </p>

        <p>
            <span class="lib_ajout_document">Origine/Exp.</span>
            <input class="type_texte" maxlength="64" size="40" type="text" placeholder="origine du document"
                   v-model="origine" />
        </p>

        <p>
            <span class="lib_ajout_document">Description</span>
            <textarea cols="40" rows="5" placeholder="description facultative"
                      v-model="description"/>
        </p>

        <p>
            <span class="lib_ajout_document">Mots clés</span>
            <textarea v-model="mots_cles" cols="40" rows="2" placeholder="mots clés facultatifs, séparés par des virgules"></textarea>
        </p>

        <p>
            <span class="lib_ajout_document">Date d'origine</span>
            <flat-pickr v-model="str_date_origine" :config="configDateTimePicker"></flat-pickr>
        </p>

        <p>
            &nbsp;
            <a href="#" @click.prevent="clickCreerDocument"><strong>Créer le document</strong></a>&nbsp;
            <a href="#" @click.prevent="clickAnnuler">Annuler</a>
        </p>
        <hr id="sous_zone_identifiant"/>
    </div>
</template>

<script>
    import ErreursSaisie from '../utilitaires/erreurs_saisie'
    import FlatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'

    import apiDocuments from '../../../api/documents'
    import apiIdentifiantsPredefinis from '../../../api/identifiants_predefinis'
    import apiOriginesPredefinies from '../../../api/origines_predefinies'
    
    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'
    import dateTimeHelpers from '../../helpers/date_time'

    import _ from 'lodash'

    export default {
        data: () => {
            return {
                fichier: null,
                identifiant: '',
                description: '',
                mots_cles: '',
                origine: '',
                str_date_origine: null,
                liste_identifiants: null,
                identifiant_selectionne: null,
                liste_origines: null,
                origine_selectionnee: null,
                erreurs: null,
                envoi_en_cours: false,
            }
        },

        props: ['dossier_parent'],

        created: function() {
            this.str_date_origine = dateTimeHelpers.dateToDDMMYYYY(new Date())

            this.chargeIdentifiantsPredefinis()
            this.chargeOriginesPredefinies()
        },

        computed: {
            dateOrigine: function() {
                if(!this.str_date_origine) {
                    return null
                }
                return dateTimeHelpers.ddmmyyyyToDate(this.str_date_origine)
            },
            configDateTimePicker: function() {
                return dateTimeHelpers.defaultFlatPickrConfig()
            }
        },

        methods: {
            chargeIdentifiantsPredefinis: function() {
                apiIdentifiantsPredefinis.getCategoriesIdentifiants()
                    .then((data) => {
                        this.liste_identifiants = _.map(data, (categorie) => {
                            let identifiants = _.map(categorie.identifiants_predefinis, (identifiant) => identifiant.libelle_formate)
                            return {
                                libelle: categorie.libelle,
                                identifiants: identifiants
                            }
                        })
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
            },

            chargeOriginesPredefinies: function() {
                apiOriginesPredefinies.getCategoriesOrigines()
                    .then((data) => {
                        this.liste_origines = _.map(data, (categorie) => {
                            let origines = _.map(categorie.origines_predefinies, (origine) => origine.libelle)
                            return {
                                libelle: categorie.libelle,
                                origines: origines
                            }
                        })
                    })
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
            },

            selectionFichier: function(event) {
                this.fichier = event?.target?.files[0]
                this.identifiant = this.fichier.name
            },

            selectionIdentifiant: function() {
                if(this.identifiant_selectionne?.length > 0) {
                    this.identifiant = this.identifiant_selectionne
                }
            },

            selectionOrigine: function() {
                if(this.origine_selectionnee?.length > 0) {
                    this.origine = this.origine_selectionnee
                }
            },

            clickAnnuler: function() {
                if(!this.envoi_en_cours) {
                    this.$emit('cloture', null)
                }
            },
            clickCreerDocument: function() {
                if(this.envoi_en_cours) {
                    return
                }

                if(!this.validation()) {
                    return
                }

                let data = {
                    identifiant: this.identifiant,
                    description: this.description,
                    origine: this.origine,
                    horodatage_origine: this.dateOrigine.toISOString(),
                    mots_cles: noeudHelpers.motsClesStringToArray(this.mots_cles),
                }

                this.envoi_en_cours = true
                let noeud_document = null
                apiDocuments.postDocumentVide(this.dossier_parent.id, data)
                .then((data) => {
                    noeud_document = data
                    return apiDocuments.postVersionDocument(noeud_document.id, this.fichier)
                })
                .then(() => {
                    this.envoi_en_cours = false
                    this.$emit('cloture', noeud_document)
                })
                .catch((error) => {
                    this.envoi_en_cours = false
                    let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                    if(data) {
                        this.erreurs = data.error
                        console.log(this.erreurs)
                    }
                })
            },
            validation: function() {
                let erreurs = []

                if(!this.fichier) {
                    erreurs.push("Aucun fichier n'a été sélectionné")
                }

                if(!(this.identifiant?.length > 0)) {
                    erreurs.push("L'identifiant est obligatoire")
                }

                if(!this.str_date_origine) {
                    erreurs.push("La date d'origine est obligatoire")
                } else if(!this.dateOrigine) {
                        erreurs.push("La date d'origine est invalide (JJ/MM/AAAA)")
                } else if(this.dateOrigine < dateTimeHelpers.cstDateMini || this.dateOrigine > dateTimeHelpers.cstDateMaxi) {
                        erreurs.push("La date d'origine est incorrecte")
                }

                if(erreurs.length === 0) {
                    this.erreurs = null
                    return true
                } else {
                    this.erreurs = erreurs
                    return false
                }
            },
        },
        components: {
            ErreursSaisie,
            FlatPickr
        }
    }
</script>
