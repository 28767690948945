import mime from 'mime-types'
import noeudHelpers from './noeud'

import imageImportant from '../../../assets/images/icones/important.png'
import imagePasImportant from '../../../assets/images/icones/pas_important.png'

import imagePageBlanche from '../../../assets/images/icones/documents/page_white.png'
import imageWord from '../../../assets/images/icones/documents/page_word.png'
import imageExcel from '../../../assets/images/icones/documents/page_excel.png'
import imagePDF from '../../../assets/images/icones/documents/page_white_acrobat.png'
import imageText from '../../../assets/images/icones/documents/page_white_text.png'
import imageImage from '../../../assets/images/icones/documents/image.png'
import imageCompresse from '../../../assets/images/icones/documents/compress.png'

const cst_type_document = {
    INDETERMINE: 0,
    IMAGE: 1,
    TEXT: 2,
    PDF: 3,
    COMPRESSE: 4,
    WORD: 5,
    EXCEL: 6,
}

function iconeTypeDocument(noeud) {
    let versionRecente = noeudHelpers.versionDocumentLePlusRecent(noeud)
    if(!versionRecente) {
        console.log("Impossible de déterminer le type d'icône à afficher pour le document")
        return imagePageBlanche
    }

    let type_mime = mime.lookup(versionRecente.nom_fichier)
    if(!type_mime) {
        console.log('Aucun type MIME connu pour le fichier : ' + versionRecente.nom_fichier)
        return imagePageBlanche
    }

    return imageTypeMIME(type_mime)
}

function imageTypeMIME(type_mime) {
    let categorie = categorie_selon_type_mime(type_mime)
    let image = imagePageBlanche;
    switch(categorie) {
        case cst_type_document.IMAGE:
            image = imageImage
            break;
        case cst_type_document.TEXT:
            image = imageText
            break;
        case cst_type_document.WORD:
            image = imageWord
            break;
        case cst_type_document.EXCEL:
            image = imageExcel
            break;
        case cst_type_document.PDF:
            image = imagePDF
            break;
        case cst_type_document.COMPRESSE:
            image = imageCompresse
            break;
    }

    return image
}

function categorie_selon_type_mime(type_mime) {
    let [type, sous_type] = type_mime.split('/')

    if(type === 'image' || type === 'text') {
        return cst_type_document.IMAGE
    }

    if(sous_type === 'pdf') {
        return cst_type_document.PDF
    }

    if(sous_type === 'zip') {
        return cst_type_document.COMPRESSE
    }

    if(sous_type === 'msword' || (sous_type.includes('openxmlformats') && sous_type.includes('word'))) {
        return cst_type_document.WORD
    }

    if(sous_type === 'excel' || (sous_type.includes('openxmlformats') && sous_type.includes('sheet'))) {
        return cst_type_document.EXCEL
    }

    return cst_type_document.INDETERMINE
}

function iconeImportance(noeud) {
                return noeud.important ?
                       imageImportant :
                       imagePasImportant
}


export default {
    cst_type_document,
    iconeTypeDocument,
    imageTypeMIME,
    iconeImportance
}