import http from './http'

function getFamilles(page, par_page, details_meta = false) {
    let url = `admin/familles_metadonnees`

    return http.get(url,
                    {
                        params: {
                            page,
                            par_page,
                            details_meta
                        }})
                    .then(function(response) {
                        let pagination = {
                            page: response.headers['pagination-page'],
                            par_page: response.headers['pagination-parpage'],
                            total_pages: response.headers['pagination-totalpages'],
                            total_items: response.headers['pagination-iotalitems']
                        }
                        return [response.data, pagination];
                    })
}

export default {
    getFamilles
}
