<template>
    <div class="required_vue_root">
        <br/>

        <p>
            <span>Libellé</span>
            &nbsp;&nbsp;
            <strong>{{ identifiantFormate }}</strong>
        </p>

        <template v-if="existeMetadonnees && !loading">
            <p v-if="estUnLien">
                Cet élément étant un lien, seul l'affichage des métadonnées est disponible.
            </p>

            <p v-if="droitSaisirMeta && !estUnLien && !estEdition">
                <a href="#" @click.prevent="editerMetadonnees">{{ libelleActionEdition }}</a>
            </p>

            <DetailsMetadonneesVisualisation
                :noeud="noeud_avec_meta"
                :familles_meta="familles_meta"
                v-if="!estEdition" />

            <DetailsMetadonneesEdition
                :noeud="noeud_avec_meta"
                :familles_meta="familles_meta"
                @fin_edition="finEdition"
                v-if="estEdition" />
        </template>

        <p v-else>
            Aucune métadonnée ne peut être saisie pour cet élément.
        </p>
    </div>
</template>

<script>

    import DetailsMetadonneesVisualisation from './details_metadonnees_visualisation'
    import DetailsMetadonneesEdition from './details_metadonnees_edition'

    import apiMetadonnees from '../../../api/metadonnees'
    import apiDossiers from '../../../api/dossiers'
    import apiDocuments from '../../../api/documents'
    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    import _ from 'lodash'

    const etatsOperations = {
        VISUALISATION: 0,
        EDITION: 1
    }

    export default {
        data: () => {
            return {
                loading: true,
                etat_courant: etatsOperations.VISUALISATION,
                noeud_avec_meta: null,
                familles_meta: [],
            }
        },

        created: async function() {
            await this._initDonnees()
        },

        computed: {
            noeud_courant: function() {
                return this.$store.getters.noeudCourant
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.noeud_courant)
            },
            estUnLien: function() {
                return noeudHelpers.estUnLien(this.noeud_courant)
            },
            droitSaisirMeta: function() {
                return this.noeud_courant.droit_ecrire
            },
            estEdition: function() {
                return this.etat_courant === etatsOperations.EDITION
            },
            existeMetadonnees: function() {
                if(!this.noeud_avec_meta) {
                    return false
                }

                for(let f of this.noeud_avec_meta.metadonnees_par_familles) {
                    if(f.metadonnees.length > 0) {
                        return true
                    }
                }
                return false
            },
            libelleActionEdition: function() {
                if(this.existeMetadonnees) {
                    return 'Modifier les métadonnées'
                } else {
                    return 'Saisir des métadonnées'
                }
            }
        },

        methods: {
            editerMetadonnees: function() {
                if(!this.droitSaisirMeta) {
                    return
                }
                this.etat_courant = etatsOperations.EDITION
            },
            finEdition: async function() {
                await this._initDonnees()
            },
            _initDonnees: async function() {
                this.loading = true
                this.etat_courant = etatsOperations.VISUALISATION
                await this._chargeNoeudAvecMeta()
                await this._chargeFamillesMetadonnes()
                this.loading = false
            },
            _chargeNoeudAvecMeta: async function() {
                let noeud_reel_id = this.estUnLien ? this.noeud_courant.noeud_reel_id : this.noeud_courant.id
                if(noeudHelpers.estUnDossier(this.noeud_courant)) {
                    this.noeud_avec_meta = await apiDossiers.lireDossier(noeud_reel_id)
                        .catch((error) => {
                            erreursHelpers.alertMessageErreur(error)
                        })
                } else {
                    this.noeud_avec_meta = await apiDocuments.lireDocument(noeud_reel_id)
                        .catch((error) => {
                            erreursHelpers.alertMessageErreur(error)
                        })
                }
            },
            _chargeFamillesMetadonnes: async function() {
                // TODO : pagination (il y a de la marge...)
                let data = await apiMetadonnees.getFamilles(1, 99, true)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })

                let [familles, pagination] = (data || ([], null))
                // this.familles_meta.splice(0, this.familles_meta.length)
                // this.familles_meta.concat(familles)
                this.familles_meta = familles
            },
        },

        watch: {
            noeud_courant: async function() {
                await this._initDonnees()
            }
        },

        components: {
            DetailsMetadonneesVisualisation,
            DetailsMetadonneesEdition
        }
    }
</script>
