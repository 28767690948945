import http from './http'

function getModeles() {
    let url = `admin/modeles_droits`

    return http.get(url)
                .then(function(response) {
                    return response.data
                })
}



export default {
    getModeles
}