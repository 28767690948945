<template>
    <div class="required_vue_root">
        <br/>
        <p>
            <select v-model="modele_selectionne_id">
                <option disabled>Choisissez un modèle...</option>
                <option v-for="modele of modeles_droits" :key="modele.id" :value="modele.id">{{ modele.identifiant }}</option>
            </select>
        </p>
        <p>
            <input type="checkbox" id="reset_droits" v-model="reset_droits">
            <label for="reset_droits">supprimer les droits avant application du modèle</label>
        </p>
       <p>
           <a href="#" @click.prevent="appliquerModele"><strong>Appliquer le modèle de droits sélectionné</strong></a>
           &nbsp;&nbsp;
           <a href="#" @click.prevent="annulerEdition">Annuler</a>
       </p>

        <br/>
        <hr/>
        <br/>

        <p><strong>Droits affectés à des groupes</strong> (recommandé)</p>
        <DetailsDroitsEditionAcls
            :acls="acls_groupes"
            :concerne="groupes"
            :est_groupe="true"
            :est_document="estDocument"
        />

        <br/>

        <p><strong>Droits affectés à des utilisateurs</strong> (déconseillé)</p>
        <DetailsDroitsEditionAcls
            :acls="acls_utilisateurs"
            :concerne="utilisateurs"
            :est_groupe="false"
            :est_document="estDocument"
        />

       <p>
           <a href="#" @click.prevent="appliquerChangements"><strong>Appliquer les changements</strong></a>
           &nbsp;&nbsp;
           <a href="#" @click.prevent="annulerEdition">Annuler</a>
       </p>
    </div>
</template>

<script>
    import DetailsDroitsEditionAcls from './details_droits_edition_acls'

    import apiNoeudsAcls from '../../../api/noeuds_acls'
    import apiModelesDroits from '../../../api/modeles_droits'

    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    import _ from 'lodash'


    export default {
        data: () => {
            return {
                modeles_droits: [],
                modele_selectionne_id: null,
                reset_droits: false,
                acls_groupes: [],
                acls_utilisateurs: []
            }
        },

        props: ['noeud', 'acls', 'groupes', 'utilisateurs'],

        created: async function() {
            await this._initDonnees()
        },

        computed: {
            estDocument: function() {
                return noeudHelpers.estUnDocument(this.noeud)
            }
        },

        methods: {
            appliquerModele: async function() {
                if(!this.modele_selectionne_id) {
                    return
                }

                let apiAppliquerModele = null
                if(noeudHelpers.estUnDossier(this.noeud)) {
                    apiAppliquerModele = apiNoeudsAcls.appliquerModeleDroitsSurDossier
                } else {
                    apiAppliquerModele = apiNoeudsAcls.appliquerModeleDroitsSurDocument
                }
                await apiAppliquerModele(this.noeud.id, this.modele_selectionne_id, this.reset_droits)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })

                this.$emit('fin_edition')
            },
            appliquerChangements: async function() {
                await this._appliquerChangementsCible(this.acls.groupes, this.acls_groupes, false)
                await this._appliquerChangementsCible(this.acls.utilisateurs, this.acls_utilisateurs, true)
                this.$emit('fin_edition')
            },
            annulerEdition: function() {
                this.$emit('fin_edition')
            },
            _initDonnees: async function() {
                this.modeles_droits = await apiModelesDroits.getModeles()
                this.acls_groupes = _.cloneDeep(this.acls.groupes)
                this.acls_utilisateurs = _.cloneDeep(this.acls.utilisateurs)
            },
            _appliquerChangementsCible: async function(acls_avant, acls_apres, flagUtilisateur) {
                let getId = flagUtilisateur ? this._getIdUtilisateur : this._getIdGroupe
                let api_delete, api_put, api_post
                if(noeudHelpers.estUnDossier(this.noeud)) {
                    api_delete = apiNoeudsAcls.deleteAclDossier
                    api_put = apiNoeudsAcls.putAclDossier
                    api_post = apiNoeudsAcls.postAclDossier
                } else {
                    api_delete = apiNoeudsAcls.deleteAclDocument
                    api_put = apiNoeudsAcls.putAclDocument
                    api_post = apiNoeudsAcls.postAclDocument
                }
                // ACL : suppressions
                let acls_a_supprimer = _.differenceWith(acls_avant, acls_apres, (x,y) => { return getId(x) === getId(y) })
                for (const acl of acls_a_supprimer) {
                    await api_delete(this.noeud.id, getId(acl), flagUtilisateur)
                        .catch((error) => {
                            erreursHelpers.alertMessageErreur(error)
                        })
                }
                // ACL : mises à jour
                let acls_a_modifier = _.intersectionWith(acls_apres, acls_avant, (x,y) => { return getId(x) === getId(y) })
                for (const acl of acls_a_modifier) {
                    await api_put(this.noeud.id, acl, flagUtilisateur)
                        .catch((error) => {
                            erreursHelpers.alertMessageErreur(error)
                        })
                }
                // ACL : créations
                let acls_a_creer = _.differenceWith(acls_apres, acls_avant, (x,y) => { return getId(x) === getId(y) })
                for (const acl of acls_a_creer) {
                    await api_post(this.noeud.id, acl, flagUtilisateur)
                        .catch((error) => {
                            erreursHelpers.alertMessageErreur(error)
                        })
                }
            },
            _getIdUtilisateur: function(acl) {
                return acl.utilisateur_id
            },
            _getIdGroupe: function(acl) {
                return acl.groupe_id
            }
        },

        components: {
            DetailsDroitsEditionAcls,
        }
    }
</script>
