<template>
    <div class="required_vue_root">
        <ErreursSaisie v-if="erreurs" v-bind:liste_erreurs="erreurs" />
        <table class="liste_dans_onglets liste_dans_onglets_navigation">
            <tbody>
                <tr>
                    <th></th>
                    <th>Familles</th>
                    <th>Champs</th>
                    <th>Valeurs</th>
                </tr>
                <tr v-for="m of metadonnees" :key="m.param_id">
                    <td class="icone_dans_tableau">
                        <input type="checkbox" v-model="m.existe_valeur" />
                    </td>
                    <td>{{ m.famille }}</td>
                    <td>{{ m.metadonnee }}</td>
                    <td>
                        <span class="grey"
                            v-if="!m.existe_valeur">
                            {{ m.valeur }}
                        </span>
                        <template v-else>
                            <input type="checkbox"
                                v-model="m.valeur"
                                true-value="1"
                                false-value="0"
                                v-if="estBooleen(m)" />
                            <input type="text"
                                v-model.trim="m.valeur"
                                v-if="estChaine(m)" />
                            <input type="number"
                                v-model="m.valeur"
                                v-if="estNumerique(m)" />
                            <select v-model="m.valeur" v-if="estEnumere(m)">
                                <option v-for="valeur of m.valeurs_possibles" :key="valeur">{{ valeur }}</option>
                            </select>
                            <flat-pickr v-model="m.valeur"
                                        :config="configDateTimePicker"
                                        v-if="estDate(m)">
                            </flat-pickr>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>

        <p>
           <a href="#" @click.prevent="appliquerChangements"><strong>Appliquer les changements</strong></a>
           &nbsp;&nbsp;
           <a href="#" @click.prevent="annulerEdition">Annuler</a>
       </p>
    </div>
</template>

<script>
    import ErreursSaisie from '../utilitaires/erreurs_saisie'
    import FlatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'

    import apiDossiers from '../../../api/dossiers'
    import apiDocuments from '../../../api/documents'

    import metadonneesHelpers from '../../helpers/metadonnees'
    import dateTimeHelpers from '../../helpers/date_time'
    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    export default {
        data: () => {
            return {
                metadonnees: [],
                erreurs: null
            }
        },

        props: ['noeud', 'familles_meta'],

        created: function() {
            this.metadonnees = metadonneesHelpers.flatMetadonnees(this.noeud, this.familles_meta)
        },

        computed: {
            configDateTimePicker: function() {
                return dateTimeHelpers.defaultFlatPickrConfig()
            }
        },

        methods: {
            estBooleen: function(m) {
                return m.type_metadonnee === metadonneesHelpers.cst_type_metadonnees.BOOLEEN
            },
            estChaine: function(m) {
                return m.type_metadonnee === metadonneesHelpers.cst_type_metadonnees.CHAINE
            },
            estNumerique: function(m) {
                return m.type_metadonnee === metadonneesHelpers.cst_type_metadonnees.NUMERIQUE
            },
            estEnumere: function(m) {
                return m.type_metadonnee === metadonneesHelpers.cst_type_metadonnees.ENUMERE
            },
            estDate: function(m) {
                return m.type_metadonnee === metadonneesHelpers.cst_type_metadonnees.DATE
            },
            appliquerChangements: async function() {
                metadonneesHelpers.majMetadonnesNoeud(this.noeud, this.metadonnees)
                let fail = false
                if(noeudHelpers.estUnDossier(this.noeud)) {
                    await apiDossiers.modifierDossier(this.noeud)
                        .catch((error) => {
                            fail = true
                            let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                            if(data) {
                                this.erreurs = data.error
                                console.log(this.erreurs)
                            }
                        })
                } else {
                    await apiDocuments.modifierDocument(this.noeud)
                        .catch((error) => {
                            fail = true
                            let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                            if(data) {
                                this.erreurs = data.error
                                console.log(this.erreurs)
                            }
                        })
                }

                if(!fail) {
                    this.$emit('fin_edition')
                }
            },
            annulerEdition: function() {
                this.$emit('fin_edition')
            },
        },

        components: {
            ErreursSaisie,
            FlatPickr
        }
    }
</script>
