<template>
    <div class="required_vue_root">
        <br/>

        <p>
            <span class="lib_infos_stockage">Libellé du dossier</span>
            <strong>{{ identifiantFormate }}</strong>
        </p>

        <hr/>

        <p v-if="famillesPourAjout.length > 0">
            Familles disponibles
            &nbsp;
            <select v-model="select_famille_id">
                <option v-for="famille of famillesPourAjout" :key="famille.id" :value="famille.id">{{ famille.libelle }}</option>
            </select>
            &nbsp;
            <a href="#" @click.prevent="clickAjouter">Ajouter</a>
        </p>

        <p v-else>Aucune famille supplémentaire ne peut être ajoutée.<p>

        <p v-if="!estRacine">
            Bloquage de l'héritage des paramètres de métadonnées :
            &nbsp;
            <input type="checkbox" v-model="bloquer_heritage" @change="marquerModifier()">
        </p>

        <p v-if="modifie">
            <strong><a href="#" @click.prevent="clickValider">Valider</a></strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href="#" @click.prevent="clickAnnuler">Annuler</a>
        </p>

        <table class="liste_dans_onglets liste_dans_onglets_navigation"
               v-if="familles_parametrees.length > 0">
            <tbody>
                <tr>
                    <th class="icone_dans_tableau"></th>
                    <th>Libellé</th>
                </tr>
                    <tr v-for="famille of familles_parametrees" :key="famille.id">
                        <td>
                            <a href="#" @click.prevent="clickSupprimer(famille.id)"><img :src="images.imageEffacer"></a>
                        </td>
                        <td>{{ famille.libelle }}</td>
                    </tr>
            </tbody>
        </table>
        <br/>

        <hr/>

        <div class="artificial_container"
             v-if="famillesHeritees.length > 0">

            <p>Liste des familles de métadonnées associés à un dossier parent et s'appliquant à ce dossier : </p>

            <table class="liste_dans_onglets liste_dans_onglets_navigation">
                <tbody>
                    <tr>
                        <th>Libellé</th>
                    </tr>
                    <tr v-for="famille in famillesHeritees" :key="famille.id">
                        <td>{{ famille.libelle }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-else>Aucune famille de métadonnées n'est héritée d'un parent.</p>
    </div>
</template>

<script>
    import apiMetadonnees from '../../../api/metadonnees'
    import apiDossiersFamillesMetadonnees from '../../../api/dossiers_familles_metadonnees'

    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    import imageEffacer from '../../../../assets/images/icone_effacer.png'

    export default {
        data: () => {
            return {
                familles_disponibles: [],
                familles_parametrees: [],
                bloquer_heritage: false,
                familles_effectives: [],
                select_famille_id: null,
                modifie: false,
                images: {
                    imageEffacer,
                }
            }
        },

        created: async function() {
            await this._initDonnees();
        },

        computed: {
            dossier_courant: function() {
                return this.$store.state.dossier_courant
            },
            estRacine: function() {
                return noeudHelpers.estRacine(this.dossier_courant)
            },
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.dossier_courant)
            },
            famillesPourAjout: function() {
                let  familles_parametrees = this.familles_parametrees
                // Toute famille qui n'est pas déjà paramétrée.
                return _.filter(this.familles_disponibles, function(f_disponible) {
                    return !_.some(familles_parametrees, function(f_parametree) {
                        return f_parametree.id === f_disponible.id
                    })
                })
            },
            famillesHeritees: function() {
                let  familles_parametrees = this.familles_parametrees
                // Toute famille effective, mais non paramétrée sur le noeud.
                return _.filter(this.familles_effectives, function(f_effective) {
                    return !_.some(familles_parametrees, function(f_parametree) {
                        return f_parametree.id === f_effective.id
                    })
                })
            }
        },

        methods: {
            clickAjouter: function() {
                let deja_present = _.some(this.familles_parametrees, (f) => {
                    return f.id === this.select_famille_id
                })
                if(deja_present) {
                    return
                }
                let famille_a_ajouter = _.find(this.familles_disponibles, (f) => {
                    return f.id === this.select_famille_id
                })
                if(!famille_a_ajouter) {
                    console.log(`familla a jouter non trouvée avec l'id ${this.select_famille_id}`)
                    return
                }
                this.familles_parametrees.push(famille_a_ajouter)
                this.marquerModifier()
            },
            clickSupprimer: function(id) {
                this.familles_parametrees = _.filter(this.familles_parametrees, (f) => {
                    return f.id !== id
                })
                this.marquerModifier()
            },
            clickValider: async function() {
                let familles_ids = _.map(this.familles_parametrees, (f) => {
                    return f.id
                })
                await apiDossiersFamillesMetadonnees.put(
                    this.dossier_courant.id,
                    this.bloquer_heritage,
                    familles_ids
                )
                .catch((error) => {
                    erreursHelpers.alertMessageErreur(error)
                })

                await this._initDonnees()
            },
            clickAnnuler: async function() {
                await this._initDonnees();
            },
            marquerModifier: function() {
                this.modifie = true
            },
            _initDonnees: async function() {
                await this._chargeFamillesMetadonnes()
                await this._chargeFamillesMetadonnesPourDossier()
                this.modifie = false
            },
            _chargeFamillesMetadonnes: async function() {
                // TODO : pagination (il y a de la marge...)
                let data = await apiMetadonnees.getFamilles(1, 99, false)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                let [familles, pagination] = (data || ([], null))
                this.familles_disponibles = familles
            },
            _chargeFamillesMetadonnesPourDossier: async function() {
                let data = await apiDossiersFamillesMetadonnees.get(this.dossier_courant.id)
                    .catch((error) => {
                        erreursHelpers.alertMessageErreur(error)
                    })
                this.familles_parametrees = _.map(data.familles_ids, (id) => {
                    return _.find(this.familles_disponibles, (famille) => {
                        return famille.id === id
                    })
                })
                this.bloquer_heritage = data.bloquer_heritage
                this.familles_effectives = _.map(data.familles_effectives_ids, (id) => {
                    return _.find(this.familles_disponibles, (famille) => {
                        return famille.id === id
                    })
                })
            },
        },

        watch: {
            dossier_courant: async function() {
                await this._initDonnees();
            }
        }

    }
</script>
