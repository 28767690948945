import Vue from 'vue/dist/vue.esm'

const cst_type_message = {
    NOUVEAU_DOSSIER: 'nouveauDossier',
    SUPPRESSION_DOSSIER : 'suppressionDossier',
    MODIFICATION_DOSSIER : 'modificationDossier',
    DEPLACEMENT_DOSSIER: 'deplacementDossier',
    NOUVEAU_DOCUMENT: 'nouveauDocument',
    SUPPRESSION_DOCUMENT : 'suppressionDocument',
    MODIFICATION_DOCUMENT : 'modificationDocument',
    DEPLACEMENT_DOCUMENT: 'deplacementDocument',

}

const messageBus = new Vue()

function nouveauDossier(noeud) {
    messageBus.$emit(cst_type_message.NOUVEAU_DOSSIER, noeud)
}

function suppressionDossier(noeud) {
    messageBus.$emit(cst_type_message.SUPPRESSION_DOSSIER, noeud)
}

function modificationDossier(noeud) {
    messageBus.$emit(cst_type_message.MODIFICATION_DOSSIER, noeud)
}

function deplacementDossier(noeud, ancien_parent_id) {
    messageBus.$emit(cst_type_message.DEPLACEMENT_DOSSIER, 
        {
            ancien_parent_id,
            noeud
        })
}

function nouveauDocument(noeud) {
    messageBus.$emit(cst_type_message.NOUVEAU_DOCUMENT, noeud)
}

function deplacementDocument(noeud, ancien_parent_id) {
    messageBus.$emit(cst_type_message.DEPLACEMENT_DOCUMENT, 
        {
            ancien_parent_id,
            noeud
        })
}

export default {
    cst_type_message,
    messageBus,

    nouveauDossier,
    suppressionDossier,
    modificationDossier,
    deplacementDossier,

    nouveauDocument,
    deplacementDocument
}