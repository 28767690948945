<template>
    <div class="required_vue_root">
        <ErreursSaisie v-if="erreurs" v-bind:liste_erreurs="erreurs" />

        <p>Edition du dossier <strong>{{ identifiantFormate }}</strong></p>

        <p>
            <span class="lib_ajout_document">Libellé </span>
            <input class="type_texte" maxlength="250" size="24" type="text" placeholder="nom du dossier"
                   v-model="identifiant" />
        </p>

        <p v-if="!estRacine">
            <span class="lib_ajout_document">Description </span>
            <textarea cols="40" rows="5" placeholder="description facultative"
                      v-model="description"/>
        </p>

        <p>
            &nbsp;
            <a href="#" @click.prevent="clickModifierDossier"><strong>Modifier le dossier</strong></a>&nbsp;
            <a href="#" @click.prevent="clickAnnuler">Annuler</a>
        </p>
    </div>
</template>

<script>
    import ErreursSaisie from '../utilitaires/erreurs_saisie'

    import apiDossiers from '../../../api/dossiers'
    import noeudHelpers from '../../helpers/noeud'
    import erreursHelpers from '../../helpers/erreurs'

    export default {
        data: () => {
            return {
                identifiant: null,
                description: null,
                erreurs: null
            }
        },

        props: ['dossier_courant'],

        created: function() {
            this.identifiant = this.dossier_courant?.identifiant ?? ''
            this.description = this.dossier_courant?.description ?? ''
        },

        computed: {
            identifiantFormate: function() {
                return noeudHelpers.identifiantFormate(this.dossier_courant)
            },
            estRacine: function() {
                return this.dossier_courant.id === this.$store.state.racine.id
            }
        },

        methods: {
            clickAnnuler: function() {
                this.$emit('cloture', null)
            },
            clickModifierDossier: function() {
                this.erreurs = null
                apiDossiers.lireDossier(this.dossier_courant.id)
                    .then((dossier_relu) => {
                        dossier_relu.identifiant = this.identifiant
                        if(!this.estRacine) {
                            dossier_relu.description = this.description
                        }
                        return apiDossiers.modifierDossier(dossier_relu)
                    })
                    .then((dossier_modifie) => {
                        this.$emit('cloture', dossier_modifie)
                    })
                    .catch((error) => {
                        let data = erreursHelpers.traiteErreurOuRetourne4xxInfo(error)
                        if(data) {
                            this.erreurs = data.error
                            console.log(this.erreurs)
                        }
                    })
            },
        },
        components: {
            ErreursSaisie,
        }
    }
</script>
