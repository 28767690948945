import http from './http'

function getStockage(noeud_id) {
    let url = `admin/dossiers/${encodeURIComponent(noeud_id)}/stockage`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function postStockage(noeud_id, stockage_id) {
    let url = `admin/dossiers/${encodeURIComponent(noeud_id)}/stockage`

    return http.post(url, { stockage_id })
                .then(function(response) {
                    return response.data
                })
}

function deleteStockage(noeud_id) {
    let url = `admin/dossiers/${encodeURIComponent(noeud_id)}/stockage`

    return http.delete(url)
                .then(function(response) {
                    return response.data
                })
}

export default {
    getStockage,
    postStockage,
    deleteStockage,
}
