<template>
    <div class="required_vue_root">
        <p v-if="loading" class="loading">
            <img :src="images.imageSpinner"/>
        </p>

        <template v-else>
            <p v-if="contenu.length === 0">
                Aucun élément n'est présent dans cette source de document.
            </p>

            <template v-else>
                <p>
                    &nbsp;<input type="checkbox" id="supprimer_apres_import" v-model="supprimer_apres_import">
                    &nbsp;<label for="supprimer_apres_import">supprimer automatiquement les fichiers après import.</label>
                </p>
                <table class="liste_dans_onglets liste_dans_onglets_sources">
                    <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Fichiers</th>
                        </tr>
                        <tr v-for="entree of contenu" :key="entree.nom">
                            <td class="icone_dans_tableau">
                                <a href="#"
                                   @click.prevent="importerFichier(entree)"
                                   class="icone_avec_infobulle">
                                    <img :src="images.imageAjoutDocument" />
                                    <span class="bulle_a_droite">Importer le fichier</span>
                                </a>
                            </td>
                            <td class="icone_dans_tableau">
                                <a href="#"
                                   @click.prevent="supprimerFichier(entree)"
                                   class="icone_avec_infobulle">
                                    <img :src="images.imageSupprimer" />
                                    <span class="bulle_a_droite">Supprimer le fichier</span>
                                </a>
                            </td>
                            <td>
                                <a :href="downloadUrl(entree)" target="_blank">{{ nomFichierFormate(entree) }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </template>
    </div>
</template>

<script>
    import apiSources from '../../../api/sources'
    import apiDocuments from '../../../api/documents'

    import erreursHelpers from '../../helpers/erreurs'
    import chaineHelpers from '../../helpers/chaines'
    import noeudHelpers from '../../helpers/noeud'

    import imageAjoutDocument from '../../../../assets/images/icones/page_white_add.png'
    import imageSupprimer from '../../../../assets/images/icones/bin_empty.png'
    import imageSpinner from '../../../../assets/images/ajax-loader.gif'

    import bus from '../../helpers/bus'

    import _ from 'lodash'

    export default {
        data: () => {
            return {
                loading: true,
                contenu: [],
                supprimer_apres_import: true,
                images: {
                    imageAjoutDocument,
                    imageSupprimer,
                    imageSpinner
                }
            }
        },

        props: ['sourceId'],

        created: async function() {
            await this._fetchContenu()
        },

        computed: {
            utilisateurCourant: function() {
                return this.$store.state.utilisateurCourant
            }
        },

        methods: {
            nomFichierFormate: function(entree){
                return chaineHelpers.limiteTailleChaine(entree.nom, 80)
            },
            downloadUrl: function(entree) {
                return apiSources.getDownloadUrlDocumentDansSourcesUtilisateur(
                    this.utilisateurCourant.id,
                    this.sourceId,
                    entree.nom)
            },
            importerFichier: async function(entree) {
                if(await this._importerFichier(entree)) {
                    if(this.supprimer_apres_import) {
                        await this.supprimerFichier(entree)
                    }
                }
            },
            _importerFichier: async function(entree) {
                let data = { identifiant: noeudHelpers.identifiantValide(entree.nom) }
                let noeud_document = null;
                try {
                    noeud_document = await apiDocuments.postDocumentVide(this.$store.state.dossier_courant.id, data)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return false
                }

                try {
                    await apiDocuments.postVersionDocumentDepuisSource(noeud_document.id, this.sourceId, entree.nom)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return fase
                }

                bus.nouveauDocument(noeud_document)
                return true
            },
            supprimerFichier: async function(entree) {
                try {
                    await apiSources.supprimeDocumentSourcesUtilisateur(this.utilisateurCourant.id, this.sourceId, entree.nom)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return
                }

                let position = _.findIndex(this.contenu, (e) => { return e.nom === entree.nom })
                if(position >= 0) {
                    this.contenu.splice(position, 1)
                }
            },
            _fetchContenu: async function() {
                this.loading = true
                try {
                    this.contenu = await apiSources.getContenuSourcesUtilisateur(this.utilisateurCourant.id, this.sourceId)
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    this.contenu = []
                }
                this.loading = false
            }
        },

        components: {
        }
    }
</script>