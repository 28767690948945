<template>
    <div id="conteneur_recherche" class="conteneur_modal" style="visibility: visible;">
        <div id="voile_recherche_ged" class="masque_modal" style="top: 0px; height: 774px;"></div>
        <div id="recherche_ged" style="top: 135px; left: 223px;">
            <p class="fermeture_modal">
                <a href="#" @click.prevent="fermer">Fermer</a>
            </p>

            <form>
                <p>
                    <a href="#" @click.prevent="change_type_recherche()">
                        {{ libelle_switch_recherche }}
                    </a>
                </p>

                <template v-if="est_recherche_simple">
                    <p>
                        <label>Information recherchée</label>
                        <input type="text" maxlength="64" size="64"
                               v-model="criteres.critere_simple"/>
                    </p>
                </template>

                <template v-else>
                    <p>
                        <label>Identifiant</label>
                        <input type="text" maxlength="64" size="64"
                               v-model="criteres.identifiant"/>
                    </p>
                    <p>
                        <label>Description</label>
                        <input type="text" maxlength="64" size="64"
                               v-model="criteres.description"/>
                    </p>
                    <p>
                        <label>Mot(s) clé(s)</label>
                        <input type="text" maxlength="64" size="64"
                               v-model="criteres.mot_cle"/>
                    </p>
                </template>

                <p>
                    <label>Recherche un</label>
                    <input type="radio" id="radio_dossier" value="0" v-model="criteres.str_type_noeud">
                    <label for="radio_dossier">dossier</label>
                    &nbsp;&nbsp;
                    <input type="radio" id="radio_document" value="1" v-model="criteres.str_type_noeud">
                    <label for="radio_document">document</label>
                </p>

                <p>
                    <label>Descendants du noeud courant</label>
                    <input type="checkbox" value="true" v-model="criteres.descendant_noeud_courant">
                </p>

                <p class="spacer">&nbsp;</p>
                <p>
                    <label>Créés entre</label>
                    <flat-pickr v-model="criteres.str_date_mini" :config="configDateTimePicker"></flat-pickr>
                    &nbsp;et&nbsp;
                    <flat-pickr v-model="criteres.str_date_maxi" :config="configDateTimePicker"></flat-pickr>
                </p>

                <p>
                    <label>Créés par</label>
                    <select width="50"
                            v-model="criteres.createur_id">
                        <option value=""></option>
                        <option v-for="u of utilisateurs" :key="u.id" :value="u.id">{{ u.identifiant }}</option>
                    </select>
                </p>

                <p>
                    <label>&nbsp;</label>
                    <a href="#" @click.prevent="rechercher"><strong>Démarrer la rechercher</strong></a>
                </p>
            </form>

            <template v-if="recherche_ok">
                <p v-if="noeuds_trouves.length === 0" class="pagination_recherche">
                    Aucun élément n'a été trouvé pour les critères indiqués.
                </p>
                <template v-else>
                    <div class="un_resultat_recherche"
                        v-for="noeud of noeuds_trouves" :key="noeud.id">
                        <p class="titre_resultat">
                            <img :src="image_noeud(noeud)"/>&nbsp;
                            <a href="#" @click.prevent="allerAuNoeud(noeud)" :class="class_identifiant(noeud)">{{ formate_identifiant(noeud) }}</a>
                        </p>
                        <p class="details_resultat">
                            Contenu dans : <CheminNoeud :noeud="noeud" @clickNoeud="allerAuNoeud($event)" />
                        </p>
                        <p class="details_resultat">
                            Créé le {{ date_creation(noeud) }}
                            par {{ utilisateur_createur(noeud).identifiant }}
                        </p>
                        <p class="details_resultat">
                            Description : {{ formate_description(noeud) }}
                        </p>
                        <p class="details_resultat">
                            Mots clés : {{ formate_mots_cles(noeud) }}
                        </p>
                    </div>
                    <hr/>
                    <p v-if="prochain_offset" class="pagination_recherche">
                        <a href="#" @click.prevent="poursuivre_recherche">
                            Poursuivre la recherche...
                        </a>
                    </p>
                    <p v-else class="pagination_recherche">
                        Aucune autre élément n'a été trouvé.
                    </p>
                </template>
            </template>

            <p v-if="loading" class="loading">
                <img :src="images.imageSpinner"/>
            </p>
        </div>
    </div>
</template>

<script>
    import CheminNoeud from '../utilitaires/chemin_noeud'
    import FlatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'

    import apiUtilisateurs from '../../../api/utilisateurs'
    import apiRecherches from '../../../api/recherches'

    import dateTimeHelpers from '../../helpers/date_time'
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'

    import imageSpinner from '../../../../assets/images/ajax-loader.gif'
    import imageDossier from '../../../../assets/images/icones/documents/folder.png'

    import _ from 'lodash'
    import utilisateurs from '../../../api/utilisateurs'

    const typeRecherche = {
        SIMPLE: 0,
        DETAILLEE: 1
    }

    export default {
        data: () => {
            return {
                loading: false,
                type_recherche: typeRecherche.SIMPLE,
                utilisateurs: [],
                criteres : {
                    str_type_noeud: 0,
                    critere_simple: '',
                    identifiant: '',
                    description: '',
                    mot_cle: '',
                    descendant_noeud_courant: false,
                    str_date_mini: null,
                    str_date_maxi: null,
                    createur_id: null
                },
                criteres_api: null,
                recherche_ok: false,
                noeuds_trouves: [],
                prochain_offset: null,
                images: {
                    imageDossier,
                    imageSpinner
                }
            }
        },

        created: async function() {
            await this._initDonnees()
        },

        computed: {
            libelle_switch_recherche: function() {
                return this.type_recherche === typeRecherche.SIMPLE ?
                        'utiliser la recherche détaillée' :
                        'utiliser la recherche simple'
            },
            est_recherche_simple: function() {
                return this.type_recherche === typeRecherche.SIMPLE
            },
            configDateTimePicker: function() {
                let config = dateTimeHelpers.defaultFlatPickrConfig()
                config.enableTime = true
                config.dateFormat = 'd/m/Y H:i'
                return config
            },
        },

        methods: {
            formate_identifiant: function(noeud) {
                return noeudHelpers.identifiantFormate(noeud, 50)
            },
            formate_description: function(noeud) {
             return noeudHelpers.descriptionFormatee(noeud)
            },
            formate_mots_cles: function(noeud) {
                return noeudHelpers.motsClesArrayToString(noeud.mots_cles)
            },
            class_identifiant: function(noeud) {
                if(noeudHelpers.estUnDossier(noeud)) {
                    return false
                }

                return noeudHelpers.estSupprime(noeud) ? 'est_supprime' : ''
            },
            image_noeud: function(noeud) {
                if(noeud.type_noeud === 0) {
                    return this.images.imageDossier
                } else {
                    return documentHelpers.iconeTypeDocument(noeud)
                }
            },
            date_creation: function(noeud) {
                return dateTimeHelpers.dateToDDMMYYYYHHMM(
                    dateTimeHelpers.isoToDate(noeud.cree_le)
                )
            },
            utilisateur_createur: function(noeud) {
                let utilisateur = _.find(this.utilisateurs, (u) => {
                    return u.id === noeud.cree_par_id
                })

                if(utilisateur) {
                    return utilisateur
                } else {
                    return {}.identifiant = '?'
                }
            },
            change_type_recherche: function() {
                this.type_recherche = this.type_recherche === typeRecherche.SIMPLE ?
                                        typeRecherche.DETAILLEE :
                                        typeRecherche.SIMPLE
            },
            fermer: function() {
                this.$emit('finRecherche')
            },
            rechercher: async function() {
                this.criteres_api = {}
                this.recherche_ok = false

                if(this.est_recherche_simple) {
                    if(this.criteres.critere_simple > "") {
                        this.criteres_api.critere_simple = this.criteres.critere_simple.trim()
                    }
                } else {
                    if(this.criteres.identifiant > "") {
                        this.criteres_api.identifiant = this.criteres.identifiant.trim()
                    }
                    if(this.criteres.description > "") {
                        this.criteres_api.description = this.criteres.description.trim()
                    }
                    if(this.criteres.mot_cle > "") {
                        this.criteres_api.mot_cle = this.criteres.mot_cle.trim()
                    }
                }

                if(this.criteres.descendant_noeud_courant) {
                    this.criteres_api.ancetre_id = this.$store.state.dossier_courant.id
                }

                if(this.criteres.str_date_mini) {
                    this.criteres_api.date_mini = dateTimeHelpers.ddmmyyyyToDate(this.criteres.str_date_mini)
                }

                if(this.criteres.str_date_maxi) {
                    this.criteres_api.date_maxi = dateTimeHelpers.ddmmyyyyToDate(this.criteres.str_date_maxi)
                }

                if(this.criteres.createur_id > 0) {
                    this.criteres_api.cree_par_id = this.criteres.createur_id
                }

                if(this.criteres_api.length <= 0) {
                    alert('Il faut saisir au moins un critère de recherche')
                    return
                }

                this.criteres_api.type_noeud = parseInt(this.criteres.str_type_noeud)

                let resultats = await this._rechercher()
                if(resultats) {
                    this.noeuds_trouves = resultats.dossiers || resultats.documents
                    this.prochain_offset = resultats.prochain_offset
                    this.recherche_ok = true
                }
            },
            poursuivre_recherche: async function() {
                if(!this.criteres_api) {
                    return
                }
                let resultats = await this._rechercher(this.prochain_offset)

                if(resultats) {
                    this.noeuds_trouves = this.noeuds_trouves.concat(resultats.dossiers || resultats.documents)
                    this.prochain_offset = resultats.prochain_offset
                }
                console.log(this.noeuds_trouves)
            },
            allerAuNoeud: function(noeud) {
                if(noeudHelpers.estUnDossier(noeud))
                {
                    this.$store.dispatch('fetchCheminCompletDossier', noeud.id)
                } else {
                    this.$store.dispatch('fetchCheminCompletDocument', noeud.id)
                }
                this.fermer()
            },
            _rechercher: async function(offset) {
                this.loading = true
                let {type_noeud, ...criteres_api} = this.criteres_api
                let api_function = type_noeud === 0 ?
                                    apiRecherches.rechercheDossiers :
                                    apiRecherches.rechercheDocuments

                let resultats
                try {
                    resultats = await api_function(criteres_api, offset, 30)
                }
                catch(error) {
                    this.loading = false
                    erreursHelpers.alertMessageErreur(error)
                    return null
                }

                this.loading = false
                return resultats
            },
            _initDonnees: async function () {
                this.criteres.str_date_mini = "01/01/1990 00:00"
                // le mois (et l'année) changement correctement si le jour > dernier jour du moins.
                // (js est ... surprenant)
                let demain_minuit = new Date()
                demain_minuit.setHours(0, 0)
                demain_minuit.setDate(demain_minuit.getDate() + 1)
                this.criteres.str_date_maxi = dateTimeHelpers.dateToDDMMYYYYHHMM(demain_minuit)


                await this._chargeUtilisateurs()
            },
            _chargeUtilisateurs: async function() {
                try {
                    this.utilisateurs = await apiUtilisateurs.getUtilisateurs()
                }
                catch(error) {
                    erreursHelpers.alertMessageErreur(error)
                    return
                }
                this.utilisateurs.sort( (a,b) => { return a.identifiant > b.identifiant })
            },
        },

        components: {
            FlatPickr,
            CheminNoeud
        }
    }
</script>