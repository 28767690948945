import http from './http'

function lireDocument(noeud_id) {
    let options = {
        details_utilisateurs: true,
        details_meta: true,
        details_documents: true
    }

    let url = `documents/${encodeURIComponent(noeud_id)}`

    return http.get(url,
                    {
                        params: { ...options }
                    }
        )
        .then(function(response) {
            return response.data
        })
}

function getAncetres(noeud_id) {
    let url = `documents/${encodeURIComponent(noeud_id)}/ancetres`

    return http.get(url,
                    {
                        params: {
                            details_utilisateurs: true,
                            details_meta: true,
                        }
            })
            .then(function(response) {
                return response.data
            })
}

function getNombreLiens(noeud_id) {
    let url = `documents/${encodeURIComponent(noeud_id)}/liens/nombre`

    return http.get(url)
            .then(function(response) {
                return response.data.nombre_liens
            })
}

function getDownloadUrl(noeud_id, no_version) {
    // baseURL content déjà un '/' à la fin
    return `${http.defaults.baseURL}documents/${encodeURIComponent(noeud_id)}/versions/${encodeURIComponent(no_version)}`
}

// Crée un document (strucute ged) sans contenu (voir versions)
// /!\ doit aller de pair avec postVersionDocument
function postDocumentVide(noeud_dossier_id, infos_document) {
    let url = `dossiers/${encodeURIComponent(noeud_dossier_id)}/documents`

    return http.post(url, infos_document)
            .then(function(response) {
                return response.data
            })
}

// A utiliser pour insérer un premier document après appel de postDocumentVide
// ou pour ajouter une nouvelle version d'un document.
function postVersionDocument(noeud_id, fichier) {
    let url = `documents/${encodeURIComponent(noeud_id)}/versions`

    let data = new FormData()
    data.append('contenu', fichier)
    return http.post(url, data)
            .then(function(response) {
                return response.data
            })
}

// A utiliser pour insérer un premier document après appel de postDocumentVide
// ou pour ajouter une nouvelle version d'un document.
function postVersionDocumentDepuisSource(noeud_id, source_id, fichier) {
    let url = `documents/${encodeURIComponent(noeud_id)}/versions`

    let data = {
        source_id,
        fichier_source: fichier
    }
    return http.post(url, data)
            .then(function(response) {
                return response.data
            })
}

function deleteDocument(noeud_id) {
    let url = `documents/${encodeURIComponent(noeud_id)}`

    return http.delete(url)
}

function ressusciterDocument(noeud_id) {
    let url = `documents/${encodeURIComponent(noeud_id)}/renaissance`

    return http.post(url)
}

function modifierDocument(document) {
    let url = `documents/${encodeURIComponent(document.id)}`

    return http.put(url,
            {
                ...document
            })
            .then(function(response) {
                return response.data
            })
}


export default {
    lireDocument,
    getAncetres,
    getNombreLiens,
    getDownloadUrl,
    postDocumentVide,
    postVersionDocument,
    postVersionDocumentDepuisSource,
    deleteDocument,
    ressusciterDocument,
    modifierDocument
}
