<template>
    <div id="navigation_ajax">
        <div id="conteneur_explorateur">
            <OuvreRecherche />
            <Arborescence />
        </div>
        <DetailsElementCourant />
    </div>
</template>

<script>
    import OuvreRecherche from './ouvre_recherche.vue'
    import Arborescence from './arborescence/arborescence.vue'
    import DetailsElementCourant from './details/details_element_courant.vue'

    export default {
        components: {
            OuvreRecherche,
            Arborescence,
            DetailsElementCourant
        }
    }
</script>