<template>
    <div class="required_vue_root">
        <p>
            <span class="lib_infos_document">Version n°</span>
            {{ version_courante.no_version }}
            <a :href="downloadDocumentUrl" target="_blank">ouvrir cette version</a>
        </p>

        <p>
            <span class="lib_infos_document">Créé le</span>
            {{ creeLe }}
        </p>

        <p>
            <span class="lib_infos_document">Créé par</span>
            {{ version_courante.cree_par.identifiant }}
        </p>

        <p>
            <span class="lib_infos_document">Nom d'origine</span>
            {{ version_courante.nom_fichier }}&nbsp;
        </p>

        <p>
            <span class="lib_infos_document">Taille</span>
            {{ version_courante.taille_fichier }}&nbsp;
        </p>

        <p>
            <span class="lib_infos_document">Type</span>
            <img :src="iconeTypeDocument" />
            {{ version_courante.type_mime }}&nbsp;
        </p>

        <hr/>

    </div>
</template>

<script>
    import apiDocuments from '../../../api/documents'
    import noeudHelpers from '../../helpers/noeud'
    import documentHelpers from '../../helpers/document'
    import erreursHelpers from '../../helpers/erreurs'
    import dateTimeHelpers from '../../helpers/date_time'

    export default {
        data: () => {
            return { }
        },

        props: ['version_courante', 'document_id'],

        computed: {
            creeLe: function() {
                return dateTimeHelpers.dateToDDMMYYYYHHMM(dateTimeHelpers.isoToDate(this.version_courante.cree_le))
            },
            iconeTypeDocument: function() {
                return documentHelpers.imageTypeMIME(this.version_courante.type_mime)
            },
            downloadDocumentUrl: function() {
                return apiDocuments.getDownloadUrl(this.document_id, this.version_courante.id)
            }
        },

        methods: {

        }

    }
</script>
