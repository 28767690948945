import http from './http'

function get(noeud_id) {
    let url = `admin/dossiers/${encodeURIComponent(noeud_id)}/familles_metadonnees`

    return http.get(url)
        .then(function(response) {
            return response.data
        })
}

function put(noeud_id,
             bloquer_heritage,
             familles_ids) {
    let url = `admin/dossiers/${encodeURIComponent(noeud_id)}/familles_metadonnees`

    return http.put(url,
                    {
                        bloquer_heritage,
                        familles_ids
                    })
                    .then(function(response) {
                        return response.data
                    })
}

export default {
    get,
    put
}
